import { Navbar, Footer } from "../App"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import passwordFogotImg from "../../img/mobile-password-forgot.png"
import { RedirectUser } from "../hook/RedirectUser"
import ErrorModal from "../hook/ModalErr"
import discord from "../../img/discord.jpg"

export function Login() {
	const [password, passwordChange] = useState("")
	const [username, loginChange] = useState("")
	const [paragraphErrText, setparagraphErrText] = useState("")
	const [errModal, setErrModal] = useState(false)

	useEffect(() => {
		if (errModal !== false) {
			setTimeout(() => {
				setErrModal(false)
			}, 3000)
		}
	}, [errModal])

	function sendDate() {
		if (username === "" || password === "") {
			setparagraphErrText("Uzupełnij pole login oraz hasło")
			setErrModal(true)
		} else {
			fetch(process.env.REACT_APP_LOGIN, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
				},
				body: JSON.stringify({ username, password }),
			})
				.then(res => res.json())
				.then(data => {
					if (data.code === "discord")
						return (
							setparagraphErrText(
								"Twoje konto zostało zarejestrowane poprzez discord, aby zalogować sie w ten sposób zresetuj hasło."
							),
							setErrModal(true)
						)
					if (data.code === 400) return setparagraphErrText("Login jest nie poprawny."), setErrModal(true)

					if (data.code === 401) return setparagraphErrText("Hasło jest nie poprawne."), setErrModal(true)
					setErrModal(true)

					if (data.code === 200) {
						localStorage.setItem("token", data.token)
						window.location.href = "/panel"
						setErrModal(false)
					}
				})
		}
	}

	return (
		<>
			<RedirectUser />
			<Navbar />
			<main>
				<ErrorModal errModal={errModal} paragraphErrText={paragraphErrText} />
				<section className="flex flex-col items-center absolute top-1/2 translate-y-[-50%]  w-full px-4 show">
					<div className="flex max-sm:w-full max-sm:flex-col items-center bg-main-color-popup rounded-lg py-5 px-5 gap-10 shadow-xl">
						<img src={passwordFogotImg} alt="image login" className="w-72 h-64 max-sm:hidden" />
						<div className="w-80 max-sm:w-11/12">
							<h2 className="text-3xl font-semibold">Logowanie</h2>
							<div className="mt-5">
								<div className="relative z-0">
									<input
										type="text"
										id="login"
										name="username"
										className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2  appearance-none  border-gray-600  focus:border-orange-custom focus:outline-none focus:ring-0  peer"
										placeholder=" "
										onKeyDown={e => {
											if (e.key === "Enter") sendDate()
										}}
										onChange={e => loginChange(e.target.value)}
									/>
									<label
										htmlFor="login"
										className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-custom peer-focus:dark:text-orange-custom peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
										Wpisz swój login
									</label>
								</div>
								<div className="relative z-0 mt-5">
									<input
										type="password"
										id="password"
										name="password"
										className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2  appearance-none  border-gray-600  focus:border-orange-custom focus:outline-none focus:ring-0  peer"
										placeholder=" "
										onKeyDown={e => {
											if (e.key === "Enter") sendDate()
										}}
										onChange={e => passwordChange(e.target.value)}
									/>
									<label
										htmlFor="password"
										className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-custom peer-focus:dark:text-orange-custom peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
										Wpisz swoje hasło
									</label>
								</div>
								<div className="flex gap-2 items-center mt-3">
									<p>
										Stwórz{" "}
										<Link to="/register" className="underline text-orange-custom">
											Konto
										</Link>
									</p>
									<p>
										Przypomnij{" "}
										<Link to="/requestResetPassword" className="underline text-orange-custom">
											Hasło
										</Link>
									</p>
								</div>
								<div className="flex items-center gap-2 mt-3">
									<button className=" bg-orange-custom py-2 px-4 rounded-lg" onClick={sendDate}>
										Zaloguj się
									</button>
									<a href={process.env.REACT_APP_DISCORD_REDIRECT}>
										<button className=" bg-discord-color py-2 px-4 rounded-lg flex items-center gap-2">
											<img src={discord} alt="discord logo" className="w-6" />
											Discord
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<div className="absolute bottom-0 flex justify-center w-full">
				<Footer />
			</div>
		</>
	)
}
