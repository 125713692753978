import React from "react"

const ErrorModal = ({ errModal, paragraphErrText }) => {
	return (
		<div
			className={`w-72 bg-main-color-popup rounded-lg absolute lg:bottom-0 lg:right-0 max-lg:top-20 max-lg:left-1/2 max-lg:translate-x-[-50%]  lg:m-5   ${
				errModal === false ? "hidden" : "block"
			}`}>
			<div className="py-5 px-2">
				<h2 className="text-xl font-semibold mb-1">Błąd</h2>
				<p>{paragraphErrText}</p>
			</div>
			<div className="w-full bg-gray-200 h-2.5 dark:bg-gray-700">
				<div className="bg-orange-custom h-2.5 animation-pogress"></div>
			</div>
		</div>
	)
}

export default ErrorModal
