import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import useCheckToken from "../hook/checkTokenHook"

export default function Auth() {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()

	const token = searchParams.get("token")

	const { dataToken } = useCheckToken(token)

	useEffect(() => {
		if (dataToken.code === 401) return

		localStorage.setItem("token", token)
        
		navigate("/panel")
	}, [dataToken])
}
