import { Navbar, Footer } from "../App"
import { useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import passwordFogotImg from "../../img/forgot-password.png"
import wallSecurity from "../../img/wallsecurity.png"
import toast, { Toaster } from "react-hot-toast"
import ErrorModal from "../hook/ModalErr"
import Loader from "../hook/Loader"
import { getDatabase, get, ref, set } from "firebase/database"

export function ForgotPassword() {
	const [password, passwordChange] = useState("")
	const [repeatPassoword, setRepeatPassowrd] = useState("")
	const [paragraphErrText, setparagraphErrText] = useState("")
	const [username, setUsername] = useState("")
	const [tokenSucces, setTokenSucces] = useState(false)
	const [errModal, setErrModal] = useState(false)
	const [showpassword, setShowPassword] = useState(false)
	const [loader, setLoader] = useState(true)
	const [searchParams] = useSearchParams()
	const token = searchParams.get("token")
	const db = getDatabase()
	const id = Date.now()
	const blackListRef = ref(db, `blacklist/` + id)
	const navigate = useNavigate()

	const event = new Date()
	const options = {
		year: "numeric",
		month: "numeric",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
	}

	const loadTokenInformation = async () => {
		const blackListCheckRef = ref(db, "blacklist/")
		try {
			const blacklist = await get(blackListCheckRef)
			const checkTokenUser = await fetch(process.env.REACT_APP_CHECK_TOKEN, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			const checkTokenData = await checkTokenUser.json()

			const checkToken = Object.values(blacklist.val()).filter(check => check.token === token)
			if (checkToken.length === 1) return setTokenSucces(true)

			if (checkTokenData.code === 200) return setUsername(checkTokenData.message.username)
			if (checkTokenData.code === 401) return setTokenSucces(true)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		loadTokenInformation()
	}, [])

	useEffect(() => {
		setTimeout(() => {
			setLoader(false)
		}, 300)
	}, [tokenSucces, username])

	useEffect(() => {
		if (errModal !== false) {
			setTimeout(() => {
				setErrModal(false)
			}, 2500)
		}
	}, [errModal])

	async function sendDate() {
		const blackListTokenSave = {
			token: token,
			time: event.toLocaleDateString("pl-PL", options),
		}

		try {
			if (password === "" || repeatPassoword === "")
				return setparagraphErrText("Uzupełnij wszystkie pola"), setErrModal(true)
			if (password.length < 6)
				return setparagraphErrText("Nowe hasło musi zawierać minimum 6 znaków"), setErrModal(true)
			if (password !== repeatPassoword) return setparagraphErrText("Hasła nie są takie same"), setErrModal(true)

			const acc = await fetch(process.env.REACT_APP_RECOVERED_ACCOUNT, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
				},
				body: JSON.stringify({ password, username }),
			})

			const accData = await acc.json()

			if (accData.code === 200)
				return (
					toast.success("Hasło do konta zostało zmienione"),
					set(blackListRef, blackListTokenSave),
					setTimeout(() => navigate("/login"), 1000)
				)
			if (accData.code === 500) return toast.error("Wystąpił nieoczekiwany bład")
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<>
			<Navbar />
			<Toaster position="top-center" reverseOrder={false} />
			<main>
				<Loader loader={loader} />
				<ErrorModal errModal={errModal} paragraphErrText={paragraphErrText} />
				<section className="flex flex-col items-center absolute top-1/2 translate-y-[-50%]  w-full px-4 ">
					<div
						className={`bg-main-color-popup ${
							tokenSucces ? "flex" : "hidden"
						} max-w-96 flex flex-col items-center text-center rounded-lg py-5 px-5  shadow-xl`}>
						<img src={wallSecurity} alt="brak dostępu" className="w-64 h-64" />
						<h2 className="text-lg my-2">
							Nie masz do tego <span className="font-semibold text-orange-custom">dostępu</span>
						</h2>
						<p>Token strony jest albo nieprawidłowy albo już wygasł</p>
					</div>
					<div
						className={`${
							tokenSucces ? "hidden" : "flex"
						} max-sm:w-full max-sm:flex-col items-center bg-main-color-popup rounded-lg py-5 px-5 gap-10 shadow-xl`}>
						<img src={passwordFogotImg} alt="image forgotpassword" className="w-72 max-sm:hidden" />
						<div className="w-80 max-sm:w-11/12">
							<h2 className="text-3xl font-semibold">Witaj {username}!</h2>
							<div className="mt-5">
								<div className="relative z-0">
									<input
										type={showpassword ? "text" : "password"}
										id="password"
										name="username"
										className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2  appearance-none  border-gray-600  focus:border-orange-custom focus:outline-none focus:ring-0  peer"
										placeholder=" "
										onChange={e => passwordChange(e.target.value)}
									/>
									<label
										htmlFor="password"
										className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-custom peer-focus:dark:text-orange-custom peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
										Hasło
									</label>
								</div>
								<div className="relative z-0 mt-5">
									<input
										type={showpassword ? "text" : "password"}
										id="repeatpassword"
										name="repeatpassword"
										className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2  appearance-none  border-gray-600  focus:border-orange-custom focus:outline-none focus:ring-0  peer"
										placeholder=" "
										onChange={e => setRepeatPassowrd(e.target.value)}
									/>
									<label
										htmlFor="repeatpassword"
										className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-custom peer-focus:dark:text-orange-custom peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
										Powtórz hasło
									</label>
								</div>
								<div className="flex items-center mt-3">
									<input
										type="checkbox"
										id="showpassword"
										onChange={() => setShowPassword(!showpassword)}
										className="w-4 h-4"
									/>
									<label htmlFor="showpassword" className="ml-1 ">
										Pokaż hasła
									</label>
								</div>

								<button className="mt-3 bg-orange-custom py-2 px-4 rounded-lg" onClick={sendDate}>
									Zmień hasło
								</button>
							</div>
						</div>
					</div>
				</section>
			</main>
			<div className="absolute bottom-0 flex justify-center w-full">
				<Footer />
			</div>
		</>
	)
}
