import React from "react"

const Loader = ({ loader }) => {
	return (
		<div
			className={`w-full ${
				!loader ? "hidden" : "flex"
			} fixed inset-0 bg-dark-custom h-full  flex-col items-center z-50 justify-center`}>
			<div className="loader"></div>
		</div>
	)
}

export default Loader
