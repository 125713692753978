import { Footer, Navbar } from "./App"
import Icon from "../img/404.svg"

export function PageNotFound() {
	return (
		<>
			<Navbar />
			<div className="flex items-center justify-center w-full absolute top-1/2 -translate-y-1/2 px-5 max-lg:flex-col max-lg:text-center">
				<div className="">
					<h2 className="text-3xl font-black text-orange-custom">Strona nie została znaleziona</h2>
					<p className="mt-3">Strona której szukasz nie istnieje bądź jest w trakcie przebudowy.</p>
					<p>Jeśli nastapił błąd skontaktuj się z administracją.</p>
				</div>
				<img src={Icon} alt="404 image not found" className="w-[30rem]" />
			</div>
			<div className="absolute bottom-0 left-1/2 -translate-x-1/2">
				<Footer />
			</div>
		</>
	)
}
