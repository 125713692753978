import { useParams } from "react-router-dom"
import { Footer, Navbar } from "../App"
import { useEffect, useState } from "react"
import {
	getDatabase,
	get,
	ref,
	limitToLast,
	orderByKey,
	query,
	limitToFirst,
	startAfter,
	orderByChild,
	equalTo,
} from "firebase/database"

export function CategoryTemplate() {
	const [lastKey, setLastKey] = useState(null)
	const [template, setTemplates] = useState([])
	const { categoriesName } = useParams()
	const db = getDatabase()

	const loadTemplate = async () => {
		const templateRef = query(
			ref(db, `Template/box`),
			orderByChild("categories"),
			equalTo(categoriesName),
			limitToLast(25)
		)
		const templatesArray = []
		let newLastKey = null

		try {
			const snapshot = await get(templateRef)

			snapshot.forEach(template => {
				if (newLastKey === null) newLastKey = template.key
				templatesArray.push(template.val())
			})
			setLastKey(newLastKey)
			setTemplates(templatesArray)
		} catch (err) {
			console.log(err)
		}
	}

	const loadMoreTemplate = async () => {
		if (!lastKey) return

		const loadMoreRef = query(ref(db, `Template/box`), orderByChild("categories"), startAfter(lastKey), limitToLast(25))
		try {
			const snapshot = await get(loadMoreRef)
			let newLastKey = null
			const moreTemplateArray = []

			snapshot.forEach(template => {
				if (template.val().categories === categoriesName) {
					if (newLastKey === null) newLastKey = template.key
					moreTemplateArray.push(template.val())
				}
			})
			if (moreTemplateArray.length > 0) {
				setLastKey(newLastKey)
				setTemplates(prevTemplates => [...prevTemplates, ...moreTemplateArray])
			}
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		loadTemplate()
	}, [])

	console.log(template)

	return (
		<>
			<Navbar />
			<h2 className="absolute top-1/2 -translate-y-1/2 text-center w-full text-5xl">Sekcja w budowie...</h2>
			<div className="absolute bottom-0 left-1/2 -translate-x-1/2">
				<Footer />
			</div>
		</>
	)
}
