import debounce from "lodash.debounce"
const { useState, useEffect } = require("react")

const useInfiniteScroll = callback => {
	useEffect(() => {
		const debuncedHandleScroll = debounce(() => {
			const scrollTop = document.documentElement.scrollTop || window.scrollY
			const scrollHeight = document.documentElement.scrollHeight
			const offset = window.innerHeight

			if (scrollTop + offset >= scrollHeight - 350) {
				callback()
			}
		}, 100)

		window.addEventListener("scroll", debuncedHandleScroll)
		return () => window.removeEventListener("scroll", debuncedHandleScroll)
	}, [callback])
}

export default useInfiniteScroll
