import { useEffect, useState } from "react"
import { Navbar, Footer } from "../App"
import { getDatabase, ref, set, update, get } from "firebase/database"
import LoaderElement from "../hook/LoaderElement"

const datebase = getDatabase()
const date = Date.now()

function checkTemplate(
	inputLink,
	setShowMoreOption,
	setTitlePlaceholder,
	setErrCreate,
	setNameTemplate,
	setDescriptioneTemplate,
	setUserData,
	setReserverTemplate,
	setTemplateRepeated,
	setLoadingAdd,
	setDataTemplate
) {
	fetch(`https://discord.com/api/v9/guilds/templates/${inputLink.split("https://discord.new/")[1]}`)
		.then(res => res.json())
		.then(data => {
			if (data.message === "404: Not Found") return setErrCreate(true)
			if (data.code !== 10057) {
				setDataTemplate(data)
				checkTemplateIsExists(
					inputLink,
					setTemplateRepeated,
					data,
					setShowMoreOption,
					setTitlePlaceholder,
					setNameTemplate,
					setDescriptioneTemplate,
					setUserData,
					setReserverTemplate,
					setErrCreate,
					setLoadingAdd
				)
			} else {
				setErrCreate(true)
				setLoadingAdd(false)
			}
		})
		.catch(err => console.log(err))
}

const checkTemplateIsExists = async (
	inputLink,
	setTemplateRepeated,
	data,
	setShowMoreOption,
	setTitlePlaceholder,
	setNameTemplate,
	setDescriptioneTemplate,
	setUserData,
	setReserverTemplate,
	setErrCreate,
	setLoadingAdd
) => {
	const templatesRef = ref(datebase, "Template/box")

	try {
		const templatesSnapshot = await get(templatesRef)

		const templatesArray = Object.keys(templatesSnapshot.val()).map(key => templatesSnapshot.val()[key])

		const isTemplateRepeated = templatesArray.some(
			template => template.link.split("https://discord.new/")[1] === inputLink.split("https://discord.new/")[1]
		)

		if (isTemplateRepeated) return setTemplateRepeated(true), setLoadingAdd(false)

		setShowMoreOption(true)
		setTitlePlaceholder(data.name)
		setErrCreate(false)
		setNameTemplate(data.name)
		setDescriptioneTemplate(data.description === null ? "Brak opisu szablonu" : data.description)
		setUserData(data)
		setReserverTemplate(data.serialized_source_guild.name)
		setLoadingAdd(false)
	} catch (err) {
		console.log(err)
	}
}

async function addTemplate(
	nameTemplate,
	descriptionTemplate,
	inputLink,
	categoryTemplate,
	setCategoryErr,
	setTemplateAddSucces,
	setTemplateRepeated,
	setdescriptionErr,
	setNameErr,
	userData,
	checkReserveTemplate,
	setReserveTemplateErr,
	serverID,
	dataTemplate
) {
	const dateCreateTemplate = new Date().toLocaleDateString("pl-Pl", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	})
	const dateref = ref(datebase, `Template/box/` + date)
	const userRef = ref(datebase, `User/${userData.creator.id}`)
	const reserveTemplateRef = ref(datebase, "ReserveTemplate")
	const profileRef = ref(datebase, `ProfileServer/${parseInt(serverID)}`)

	const dateCreate = {
		ID: date,
		title: nameTemplate.normalize("NFKD").replace(/[\u0300-\u036f]/g, ""),
		description: descriptionTemplate === "" ? "Brak opisu szablonu" : descriptionTemplate,
		link: inputLink,
		dateCreate: dateCreateTemplate,
		categories: categoryTemplate,
	}

	const coinsToAdd =
		dataTemplate.serialized_source_guild &&
		(
			dataTemplate.serialized_source_guild.channels.length / 5 +
			dataTemplate.serialized_source_guild.roles.length / 5 +
			3
		).toFixed(2)

	function userTemplateCreate(userUpdate) {
		if (userUpdate.templates) {
			const newTemplates = [...userUpdate.templates, date]

			update(userRef, {
				templateCount: userUpdate.templateCount + 1,
				templates: newTemplates,
			})
		} else {
			update(userRef, {
				templateCount: userUpdate.templateCount + 1,
				templates: [date],
			})
		}
	}

	function userTemplateNotExists() {
		const data = {
			username: userData.creator.username,
			avatar: userData.creator.avatar,
			userId: userData.creator.id,
			templateCount: 1,
			templates: [date],
		}
		set(userRef, data)
	}

	function resetValue() {
		setNameErr(false)
		setTemplateAddSucces(true)
		setCategoryErr(false)
		setTemplateRepeated(false)
		setdescriptionErr(false)
		setReserveTemplateErr(false)
	}

	try {
		const reserveTemplateSnapshot = await get(reserveTemplateRef)
		const profileServer = await get(profileRef)
		const userDataRef = await get(userRef)

		const reserveArray = Object.values(reserveTemplateSnapshot.val())
		const dataProfile = profileServer.val()

		const reserveTemplateActive = reserveArray.some(template => template.name === checkReserveTemplate)
		const userData = userDataRef.val()

		if (reserveTemplateActive) return setReserveTemplateErr(true)
		if (categoryTemplate === "") return setCategoryErr(true)
		if (descriptionTemplate.length > 60) return setdescriptionErr(true), setCategoryErr(false)
		if (nameTemplate.length >= 35) return setNameErr(true), setdescriptionErr(false), setCategoryErr(false)

		if (serverID !== "") {
			dateCreate.server = dataProfile.name
			dateCreate.avatarServer = dataProfile.avatar
			dateCreate.serverLink = dataProfile.link
			update(profileRef, { templatesCount: dataProfile.templatesCount + 1 })
			set(dateref, dateCreate)
			resetValue()
		} else {
			set(dateref, dateCreate)
			resetValue()
		}

		if (userDataRef.exists()) {
			userTemplateCreate(userData)
		} else {
			userTemplateNotExists()
		}

		console.log(userData.coins)

		if (userData.coins === undefined || userData.coins === 0) {
			update(userRef, { coins: coinsToAdd })
		} else {
			update(userRef, {
				coins: parseFloat(userData.coins) + parseFloat(coinsToAdd),
			})
		}
	} catch (err) {
		console.log(err)
	}
}

export function TemplateCreate() {
	const [inputLink, setInputLink] = useState("")
	const [showMoreOption, setShowMoreOption] = useState(false)
	const [titlePlaceholder, setTitlePlaceholder] = useState("")
	const [errCreate, setErrCreate] = useState(false)
	const [nameTemplate, setNameTemplate] = useState("")
	const [descriptionTemplate, setDescriptioneTemplate] = useState("")
	const [categoryTemplate, setCategoryTemplate] = useState("")
	const [categoryErr, setCategoryErr] = useState(false)
	const [templateAddSucces, setTemplateAddSucces] = useState(false)
	const [templateRepeated, setTemplateRepeated] = useState(false)
	const [descriptionErr, setdescriptionErr] = useState(false)
	const [nameErr, setNameErr] = useState(false)
	const [userData, setUserData] = useState("")
	const [checkReserveTemplate, setReserverTemplate] = useState("")
	const [reserveTemplateErr, setReserveTemplateErr] = useState(false)
	const [loadingAdd, setLoadingAdd] = useState(false)
	const [serverList, setServerList] = useState([])
	const [dataTemplate, setDataTemplate] = useState([])
	const [serverID, setServerID] = useState(() => {
		return localStorage.getItem("Server") || ""
	})
	const [showMoreOptionServer, setShowMoreOptionServer] = useState(false)

	useEffect(() => {
		setLoadingAdd(false)
	}, [errCreate, categoryErr, descriptionErr, nameErr])

	async function serversList() {
		const baseRef = ref(datebase, `ProfileServer/`)

		const snapshot = await get(baseRef)

		Object.values(snapshot.val()).forEach(snapshotUser => {
			if (snapshotUser.userServer) {
				const checkUser = Object.values(snapshotUser.userServer).some(users => users === userData.creator.id)
				const list = {
					nameServer: snapshotUser.name,
					id: snapshotUser.id,
				}
				if (checkUser) return setServerList(prevList => [...prevList, list])
			}
		})
	}

	useEffect(() => {
		if (showMoreOption) {
			serversList()
		}
	}, [showMoreOption])

	useEffect(() => {
		localStorage.setItem("Server", serverID)
	}, [serverID])

	return (
		<>
			<Navbar />
			<main className="max-w-container mx-auto mb-24">
				<section>
					<div
						className={`template-add-err flex flex-col ${
							reserveTemplateErr === false ? "hidden" : "block"
						} items-center mt-10`}>
						<div className="template-add-err-main">
							<h2 className="text-4xl my-5">Szablon Zastrzeżony</h2>
							<p></p>
						</div>
						<div className="template-add-err-info text-center bg-error-custom w-96  px-2 py-5 rounded-lg shadow-2xl shadow-error-custom">
							<p className="text-2xl font-medium">
								Szablon {checkReserveTemplate} został zastrzeżony i nie można go dodać.
							</p>
							<div className="buttons flex gap-1 justify-center mt-5">
								<a href="/create">
									<button className="bg-orange-custom py-2 px-3 rounded-lg">Dodaj więcej</button>
								</a>
								<a href="/templates">
									<button className="bg-orange-custom py-2 px-3 rounded-lg">Pokaż szablony</button>
								</a>
							</div>
						</div>
					</div>
					<div
						className={`template-add-err flex flex-col ${
							templateRepeated === false ? "hidden" : "block"
						} items-center mt-10`}>
						<div className="template-add-err-main">
							<h2 className="text-4xl my-5">Szablon powtórzony</h2>
							<p></p>
						</div>
						<div className="template-add-err-info text-center bg-error-custom w-96  px-2 py-5 rounded-lg shadow-2xl shadow-error-custom">
							<p className="text-2xl font-medium">Niestety ale twój szablon już istnieje i nie moze zostać dodany..</p>
							<div className="buttons flex gap-1 justify-center mt-5">
								<a href="/create">
									<button className="bg-orange-custom py-2 px-3 rounded-lg">Dodaj więcej</button>
								</a>
								<a href="/templates">
									<button className="bg-orange-custom py-2 px-3 rounded-lg">Pokaż szablony</button>
								</a>
							</div>
						</div>
					</div>
					<div
						className={`template-add-succes ${templateAddSucces === true ? "flex" : "hidden"}  
						flex-col items-center mt-10`}>
						<div className="template-add-succes-man">
							<h2 className="text-4xl my-5">Szablon został dodany</h2>
							<p></p>
						</div>
						<div className="template-add-succes-info text-center bg-error-custom w-96  px-2 py-5 rounded-lg shadow-2xl shadow-error-custom">
							<p className="text-2xl font-medium">
								Twój szablon został pomyślnie dodany na naszą stronę. Dziękujemy :)
							</p>
							<div className="buttons flex gap-1 justify-center mt-5">
								<a href="/create">
									<button className="bg-orange-custom py-2 px-3 rounded-lg">Dodaj więcej</button>
								</a>
								<a href={`/templates/${date}`}>
									<button className="bg-orange-custom py-2 px-3 rounded-lg">Pokaż szablon</button>
								</a>
							</div>
						</div>
					</div>
					<div
						className={`create ${templateAddSucces === true ? "hidden" : "block"} ${
							templateRepeated === false ? "block" : "hidden"
						} ${reserveTemplateErr === false ? "block" : "hidden"} px-2 mb-24`}>
						<div className="template-create flex flex-col items-center mt-10">
							<div className="template-create-title text-left w-full max-w-[75rem]  ">
								<h2 className="text-4xl font-semibold">Dodaj szablon</h2>
								<p className={`text-lg ${showMoreOption ? "hidden" : "block"}`}>
									Wklej link do swojego szablony aby dodać go na listę szablonów
								</p>
								<p className={`${showMoreOption ? "block" : "hidden"} text-lg`}>
									<p>
										Za dodanie tego szablonu do konta użytkownika{" "}
										<span className="text-orange-custom font-black">
											{dataTemplate.creator && dataTemplate.creator.username}
										</span>{" "}
										zostanie przypisane{" "}
										{dataTemplate.serialized_source_guild &&
											(
												dataTemplate.serialized_source_guild.channels.length / 5 +
												dataTemplate.serialized_source_guild.roles.length / 5 +
												3
											).toFixed(2)}{" "}
										coinsów.
									</p>
								</p>
							</div>
							<div className=" template-create-main bg-orange bg-main-color-popup max-w-[75rem]  py-5 px-2 rounded-lg mt-2">
								<div className="w-full">
									<h2 className="text-3xl ">Twój szablon</h2>
									<p className="py-2">
										Aby zdobyć swój szablon musisz wejść na discorda → Ustawienia serwera → Szablon serwera → Uzupełnić
										tytuł oraz opis szablonu → kliknij przycisk Wygeneruj Szablon. Otrzymany link wklej poniżej.{" "}
									</p>
									<div className="input flex flex-col relative">
										<label htmlFor="template-link" className="text-lg">
											Link
										</label>
										<input
											type="text"
											defaultValue={"https://discord.new/"}
											readOnly={showMoreOption === true}
											className={`bg-[#24242c] py-2 px-2 outline-black rounded-lg input1 border-[1px] border-border-custom ${
												errCreate === true ? "border-red-700" : ""
											} ${showMoreOption === true ? "focus:outline-none" : ""} `}
											id="template-link"
											onChange={e => setInputLink(e.target.value)}
										/>
										<span
											className={`material-symbols-outlined absolute top-1/2 right-2 text-red-700 ${
												errCreate === true ? "border-red-700 block" : "hidden"
											} `}>
											warning
										</span>
									</div>

									<div className={`template-form ${showMoreOption === false ? "hidden" : "flex"} flex-col mt-2`}>
										<div className="name-input flex items-center gap-2">
											<label htmlFor="template-name" className="text-lg">
												Nazwa
											</label>
											<p className="text-red-700 text-sm">
												{nameErr !== false ? "(Nazwa za długa, max 30 znaków)" : ""}
											</p>
										</div>

										<input
											type="text"
											className={`bg-[#24242c] py-2 px-2 outline-black rounded-lg border-[1px] ${
												nameErr !== false ? " border-red-700" : ""
											} border-border-custom`}
											id="template-name"
											defaultValue={titlePlaceholder.normalize("NFKD").replace(/[\u0300-\u036f]/g, "")}
											onChange={e => {
												setNameTemplate(e.target.value)
											}}
										/>
										<div className="name-input flex items-center gap-2">
											<label htmlFor="template-description" className="text-lg">
												Opis
											</label>
											<p className="text-red-700 text-sm">
												{descriptionErr !== false ? "(Opis za długi, max 60 znaków)" : ""}
											</p>
										</div>
										<textarea
											id="template-description"
											rows="2"
											className={`bg-[#24242c] resize-none rounded-lg ${
												descriptionErr !== false ? " border-red-700" : ""
											} px-2 py-1 border-[1px] border-border-custom`}
											value={descriptionTemplate === "" ? "Brak opisu szablonu" : descriptionTemplate}
											onChange={e => setDescriptioneTemplate(e.target.value)}></textarea>

										<label htmlFor="template-category" className="text-lg mt-2">
											Wybierz kategorie
										</label>
										<select
											id="template-category"
											className={` bg-[#24242c] py-2 rounded-lg border-[1px] ${
												categoryErr === true ? "border-red-700" : ""
											} border-border-custom`}
											onChange={e => setCategoryTemplate(e.target.options[e.target.selectedIndex].text)}>
											<option value="0" select>
												Wybierz kategorie
											</option>
											<option value="1">Wszystkie</option>
											<option value="2">Społecznościowe</option>
											<option value="3">Roleplay</option>
											<option value="4">Tematyczne</option>
											<option value="5">Programistyczne</option>
											<option value="6">Angielskie</option>
											<option value="7">NSFW</option>
											<option value="8">Grupy-ludzi</option>
											<option value="9">Gildie</option>
											<option value="10">Memy</option>
											<option value="11">Scam</option>
											<option value="12">Anime</option>
											<option value="13">GTA</option>
											<option value="14">Minecraft</option>
											<option value="15">Gamingowe</option>
											<option value="16">Reklamowe</option>
											<option value="17">Zdobione</option>
										</select>
										<button
											className={`text-lg mt-2 w-fit ${
												showMoreOptionServer || serverList.length === 0 ? "hidden" : "block"
											}`}
											onClick={() => setShowMoreOptionServer(!showMoreOptionServer)}>
											Masz <span className="text-orange-custom font-semibold">{serverList.length}</span> serwery sprawdź
											to!
										</button>
										<div
											className={`mt-2 ${serverList.length === 0 ? "hidden" : "block"} ${
												showMoreOptionServer ? "block" : "hidden"
											}`}>
											<div className="flex items-center mt-2">
												<label htmlFor="template-server" className="text-lg">
													Wybierz Serwer
												</label>
												<p className={`ml-2 text-green-500 ${serverID !== "" ? "visible" : "hidden"}`}>Zapisano</p>
											</div>
											<select
												id="template-server"
												className="bg-[#24242c] py-2 rounded-lg border-[1px] w-full  border-border-custom"
												value={serverID}
												onChange={e => setServerID(e.target.value)}>
												<option value="" select>
													Wybierz kategorie
												</option>
												{serverList.map((server, index) => (
													<>
														<option key={index} value={server.id}>
															{server.nameServer}
														</option>
													</>
												))}
											</select>
										</div>
									</div>
								</div>

								<div className="flex items-center mt-2 gap-2">
									<button
										className="bg-orange-custom py-1 px-3 rounded-lg"
										onClick={() => {
											if (showMoreOption) {
												addTemplate(
													nameTemplate,
													descriptionTemplate,
													inputLink,
													categoryTemplate,
													setCategoryErr,
													setTemplateAddSucces,
													setTemplateRepeated,
													setdescriptionErr,
													setNameErr,
													userData,
													checkReserveTemplate,
													setReserveTemplateErr,
													serverID,
													dataTemplate
												)
											} else {
												checkTemplate(
													inputLink,
													setShowMoreOption,
													setTitlePlaceholder,
													setErrCreate,
													setNameTemplate,
													setDescriptioneTemplate,
													setUserData,
													setReserverTemplate,
													setTemplateRepeated,
													setLoadingAdd,
													setDataTemplate
												)
											}
											setLoadingAdd(true)
										}}>
										{showMoreOption === false ? "Dalej" : "Dodaj "}
									</button>
									<LoaderElement loaders={loadingAdd} />
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<div className="absolute bottom-0 left-1/2 translate-x-[-50%]">
				<Footer />
			</div>
		</>
	)
}
