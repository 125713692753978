import "../css/input.css"
import Logo from "../img/Logo.png"
import Main from "../img/Main.png"
import { useState, useEffect } from "react"
import { useLocation, Link } from "react-router-dom"
import { getDatabase, ref, onValue } from "firebase/database"
import templateAvatar from "../img/dc_szablony_awatar.jpg"
import shizecloneAvatar from "../img/shizeclone-avatar.jpg"
import AOS from "aos"
import "aos/dist/aos.css"
import userLogoDiscord from "../img/user-logo.webp"
import Loader from "./hook/Loader"
import "nprogress/nprogress.css"
import NProgress from "nprogress"
import useCheckToken from "./hook/checkTokenHook"

export function Loading() {
	const location = useLocation()

	useEffect(() => {
		NProgress.start()

		const timer = setTimeout(() => {
			NProgress.done()
		}, 500)

		return () => {
			clearTimeout(timer)
		}
	}, [location])
}

export function Footer() {
	const year = new Date()
	let currentYear = year.getFullYear()

	return (
		<footer className="py-5 text-center">
			<p>Copyright & {currentYear} Shizer</p>
			<div className="flex justify-center gap-1 mt-1">
				<a href="/templates" className="text-gray-500">
					Szablony
				</a>
				<a href="/create" className="text-gray-500">
					Dodaj szablon
				</a>
			</div>
		</footer>
	)
}

export function Navbar() {
	const [isHamburgerMenuVisible, setBurgerMenu] = useState(false)
	const [isUserLogin, setLocation] = useState(false)
	const location = useLocation()
	const token = localStorage.getItem("token")
	const { dataToken } = useCheckToken(token)

	useEffect(() => {
		if (dataToken.code === 200) setLocation(true)
		if (dataToken.code === 401) setLocation(false)
	}, [dataToken])

	function OptionNav() {
		return (
			<>
				<li className={`${location.pathname === "/" ? "text-orange-custom" : ""} my-2`}>
					<Link to="/">Home</Link>
				</li>
				<li className={`${location.pathname === "/templates" ? "text-orange-custom" : ""} my-2`}>
					<Link to="/templates">Szablony</Link>
				</li>
				<li className={`${location.pathname === "/api/docs" ? "text-orange-custom" : ""} my-2`}>
					<Link to="/api/docs">API</Link>
				</li>
				<li className={`${location.pathname === "/login" ? "text-orange-custom" : ""} my-2`}>
					<Link to={isUserLogin ? "/panel" : "/login"}>{isUserLogin ? "Panel" : "Logowanie"}</Link>
				</li>
			</>
		)
	}

	return (
		<>
			<nav>
				<div className="absolute w-full z-20">
					<ul
						className={`menu-mobile ${
							isHamburgerMenuVisible ? "menu-mobile" : "show-menu"
						} flex flex-col items-center justify-center gap-2 sm:hidden  h-screen   z-20 bg-popup-custom  text-2xl font-medium relative tracking-wider overflow-hidden`}>
						<img src={Logo} alt="logo" className="w-28 absolute top-5" />
						<OptionNav />
					</ul>
				</div>
				<div className="navbar flex items-center justify-between px-10 shadow-box-custom">
					<div className="navbar-img">
						<Link to="/">
							<img src={Logo} alt="Mascot logo szablony" className="w-16" />
						</Link>
					</div>
					<div className="navbar-item max-sm:hidden">
						<ul className="flex gap-3 text-xl font-medium">
							<OptionNav />
						</ul>
					</div>
					<div className="navbar-item-mobile max-sm:block sm:hidden z-30">
						<button onClick={() => setBurgerMenu(prev => !prev)}>
							<span className="material-symbols-outlined text-[2.50rem]">
								{isHamburgerMenuVisible ? "close" : "menu"}
							</span>
						</button>
					</div>
				</div>
			</nav>
		</>
	)
}

export function App() {
	const [loaders, setLoader] = useState(true)
	const [topUser, setTopUser] = useState([])
	const [searchUser, setSearchUser] = useState("")
	const [searchUserResult, setSearchUserResult] = useState([])
	const [visibleUser, setVisibleUser] = useState(10)
	const [searchPopup, setSearchPopup] = useState(false)
	const [category, setCategory] = useState([])

	const loadTemplateCategory = async () => {
		try {
			const category = await fetch(process.env.REACT_APP_CATEGORY, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
				},
			})
			const categoryData = await category.json()

			setCategory(categoryData.message)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		const db = getDatabase()
		const userRef = ref(db, "User/")

		onValue(userRef, snapshot => {
			const dataUser = snapshot.val()
			const dataArray = Object.values(dataUser)

			dataArray.sort((a, b) => b.templateCount - a.templateCount)

			setTopUser(dataArray)
		})

		AOS.init({
			duration: 1000,
		})

		loadTemplateCategory()
	}, [])

	useEffect(() => {
		if (topUser && category) {
			setTimeout(() => {
				setLoader(false)
			}, 300)
		}
	}, [topUser, category])

	useEffect(() => {
		const filterMessage = topUser.filter(test => test.username.toLocaleLowerCase().includes(searchUser))
		setSearchUserResult(filterMessage)
	}, [searchUser, topUser])

	const cooperation = [
		{
			name: "Szablony Polska",
			description: "Współpracujemy aktualnie jednym z topki serwerów discord szablonowych.",
			buttonName: "Dołącz",
			href: "https://discord.gg/szablony-r-988496751063343115",
			img: templateAvatar,
			id: "Template",
		},
		{
			name: "ShizeClone",
			description: "Jedyny kloner serwerów discord w polsce, wysoka pozycja domeny",
			buttonName: "Skorzystaj",
			href: "https://shizeclone.eu",
			img: shizecloneAvatar,
			id: "ShizeClone",
		},
	]

	useEffect(() => {
		if (searchPopup === true) {
			document.documentElement.style.overflow = "hidden"
		} else {
			document.documentElement.style.overflow = "auto"
		}
	}, [searchPopup])

	return (
		<>
			<Loader loader={loaders} />
			<Navbar />
			{searchPopup !== false && (
				<div className="fixed top-0 left-0 w-full h-full bg-black-transparent overflow-y-hidden z-50"></div>
			)}
			<div
				className={`search-user ${
					searchPopup === false ? "hidden" : "block"
				}   bg-popup-custom fixed top-2/4 left-2/4 translate-x-[-50%] translate-y-[-50%] w-[28rem] px-4 py-3 rounded-xl max-xl:w-[50%] 
				max-md:w-11/12 overflow-auto z-[150]`}>
				<div className="popup-main flex justify-between text-xl">
					<h2>Wyszukaj użytkownika</h2>
					<button>
						<span
							className="material-symbols-outlined"
							onClick={() => {
								setSearchPopup(false)
							}}>
							close
						</span>
					</button>
				</div>

				<hr />
				<div className="items">
					<div className="search my-2">
						<label htmlFor="search-user">Wprowadź nazwę użytkownika</label>
						<input
							id="search-user"
							type="text"
							className="w-full py-1 px-2 bg-input-custom rounded-lg my-1"
							placeholder="Nick"
							onChange={e => setSearchUser(e.target.value)}
						/>
					</div>
					<h2>{searchUserResult.length} osób które dodały szablony.</h2>
					<div className="search-result my-3 max-h-64 overflow-y-scroll">
						{searchUserResult.slice(0, visibleUser).map(user => (
							<>
								<a href={`/user/${user.userId}`}>
									<div className="flex gap-2 py-2">
										<div className="">
											<img
												src={
													user.avatar === undefined || user.userId === undefined
														? userLogoDiscord
														: `https://cdn.discordapp.com/avatars/${user.userId}/${user.avatar}.webp`
												}
												alt="avatar uzytkownika"
												className="w-16"
											/>
										</div>
										<div className="">
											<h2 className="font-semibold text-orange-custom text-xl">{user.username}</h2>
											<p>
												{user.templateCount <= 5 ? `${user.templateCount} szablony` : `${user.templateCount} szablonów`}
											</p>
										</div>
									</div>
								</a>
							</>
						))}
						<h2
							className={`text-center ${
								visibleUser >= topUser.length ? "hidden" : "block"
							} transition-colors hover:text-orange-custom cursor-pointer`}
							onClick={() => setVisibleUser(prev => prev + 10)}>
							Pokaż więcej
						</h2>
					</div>
				</div>
			</div>
			<header className="flex justify-center h-[90%] relative">
				<div className="absolute -right-44 bottom-44 w-[400px] h-[400px] opacity-30 bg-orange-custom blur-[110px] rounded-full"></div>
				<div className="absolute -left-44 top-44 w-[250px] h-[250px] opacity-20 bg-orange-custom blur-[80px] rounded-full"></div>
				<div
					className={`${
						loaders ? "hidden" : "flex"
					} header items-center justify-center   flex-row-reverse px-5 max-md:flex-col`}>
					<div className="header-img max-md:flex max-md:justify-center">
						<img src={Main} alt="logo discord mascot" className="main-img max-md:w-9/12" />
					</div>
					<div className="header-text max-md:text-center max-xl:bg-none">
						<h1 className="main-text-h1 text-5xl font-semibold tracking-wide">
							<span className="text-orange-custom mr-2 font-extrabold relative inline-block stroke-current text-5xl">
								Szablony
								<svg
									class="absolute -bottom-0.5 w-full max-h-1.5"
									viewBox="0 0 55 5"
									xmlns="http://www.w3.org/2000/svg"
									preserveAspectRatio="none">
									<path d="M0.652466 4.00002C15.8925 2.66668 48.0351 0.400018 54.6853 2.00002" stroke-width="2"></path>
								</svg>
							</span>
							Polska
						</h1>
						<p className="main-text-p mt-2 max-w-xl">
							Jesteśmy jedynym z serwerów discord opartych na szablonach gdzie wykorzystujemy swoje bardzo zaawansowane
							autorskie boty oraz systemy
						</p>
						<a href="https://discord.gg/szablony-r-988496751063343115">
							<button className="border border-orange-custom text-orange-custom py-2 px-6 mt-5 rounded-xl transition-colors hover:bg-orange-custom hover:text-black ">
								Discord
							</button>
						</a>
					</div>
				</div>
				<a href="#templates" className="absolute bottom-0">
					<span className="material-symbols-outlined  text-5xl animate-bounce transition-colors hover:text-orange-custom max-sm:hidden">
						expand_more
					</span>
				</a>
			</header>
			<main>
				<div className="bg-main-color-popup">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 150">
						<path
							fill="#1f252e"
							fill-opacity="1"
							d="M0,96L48,85.3C96,75,192,53,288,56C384,59,480,85,576,93.3C672,101,768,91,864,77.3C960,64,1056,48,1152,48C1248,48,1344,64,1392,72L1440,80L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
					</svg>
					<section
						id="templates"
						className="template-length bg-main-color-popup max-w-container mx-auto"
						data-aos="fade-right">
						<h2 className="text-4xl tracking-wider font-black text-center">Szablony</h2>
						<div className="w-16 h-1 mx-auto mt-1 bg-orange-custom"></div>

						<div className="w-full flex justify-center flex-wrap mt-5 px-2">
							<div className="template-length w-9/12 max-md:w-full flex gap-5 flex-wrap justify-center">
								{Object.keys(category).map((template, index) => (
									<div
										className="w-60 max-md:w-72 max-sm:w-11/12 bg-transparent-number py-4 px-3 rounded-lg"
										key={index}>
										<a href={`${`/templates?category=${template}`}`} className="text-2xl text-orange-custom font-[525]">
											{template}
										</a>
										<p>
											{category[template]}
											<span> szablonów</span>
										</p>
									</div>
								))}
							</div>
						</div>
						<p className={`${category.length === 0 ? "block" : "hidden"} text-red-500 text-center font-bold`}>
							Wystąpił błąd podczas łączeniem z backendem..
						</p>
						<p className="text-center mt-2">*Szablony zostały podzielone na powyższe kategorie.</p>
					</section>
					<section className="mt-20">
						<div className="w-full" data-aos="fade-right">
							<h2 className="text-4xl tracking-wider font-black text-center">Współprace</h2>
							<div className="w-16 h-1 mx-auto mt-1 bg-orange-custom"></div>

							<div className="item flex max-md:flex-col max-md:items-center justify-center  mt-5 gap-2 py-4 px-2">
								{cooperation.map(info => (
									<div
										className="flex  gap-2 w-[30rem] max-md:w-11/12 bg-transparent-number rounded-lg py-4 px-2"
										key={info.id}>
										<img src={info.img} alt="avatar szablonow" className="w-32 object-cover rounded-lg" />
										<div className="flex flex-col items-start">
											<h2 className="text-xl font-black">{info.name}</h2>
											<p className="mt-2">{info.description}</p>
											<a href={info.href}>
												<button className="mt-2 py-1 px-2 bg-orange-custom rounded-lg">{info.buttonName}</button>
											</a>
										</div>
									</div>
								))}
							</div>
						</div>
					</section>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120">
						<path
							fill="#1f252e"
							fill-opacity="1"
							d="M0,0L48,14.7C96,29,192,59,288,59C384,59,480,29,576,21.7C672,14,768,29,864,42.7C960,56,1056,72,1152,72C1248,72,1344,56,1392,48L1440,40L1440,150L1392,150C1344,150,1248,150,1152,150C1056,150,960,150,864,150C768,150,672,150,576,150C480,150,384,150,288,150C192,150,96,150,48,150L0,150Z"></path>
					</svg>
				</div>
				<section className="mb-10 px-2" data-aos="fade-right">
					<h2 className="text-4xl tracking-wider font-black text-center">Topka</h2>
					<div className="absolute -left-44 top-44 w-[250px] h-[250px] opacity-20 bg-orange-custom blur-[80px] rounded-full"></div>
					<div className="absolute -right-44 top-4 w-[250px] h-[250px] opacity-20 bg-orange-custom blur-[80px] rounded-full"></div>
					<div className="w-16 h-1 mx-auto mt-1 bg-orange-custom"></div>
					<div className="trophea flex max-md:flex-col max-md:items-center justify-center mt-20 gap-10 px-2">
						{topUser[2] && (
							<a href={`/user/${topUser[2].userId}`}>
								<div className="item flex flex-col items-center bg-transparent-number w-64 py-5 h-64 text-center rounded-lg relative scale-105">
									<span className="material-symbols-outlined absolute top-[-15%] left-1/2 translate-x-[-50%] text-5xl text-bronze">
										trophy
									</span>
									<div className="">
										<img
											src={
												topUser[2].avatar === undefined || topUser[2].userId === undefined
													? userLogoDiscord
													: `https://cdn.discordapp.com/avatars/${topUser[2].userId}/${topUser[2].avatar}.webp`
											}
											alt="avatar uzytkownika"
											className="w-32 rounded-full border-2 border-bronze"
										/>
									</div>
									<div className="">
										<h2 className="text-2xl py-2 text-orange-custom font-semibold">{topUser[2].username}</h2>
										<p>{topUser[2].templateCount} szablonów</p>
									</div>
								</div>
							</a>
						)}
						{topUser[0] && (
							<a href={`/user/${topUser[0].userId}`}>
								<div className="item max-md:my-10 flex flex-col items-center bg-transparent-number w-64 py-5 h-64 text-center rounded-lg relative scale-110">
									<span className="material-symbols-outlined absolute top-[-15%] left-1/2 translate-x-[-50%] text-5xl text-yellow-300  ">
										trophy
									</span>
									<div className="">
										<img
											src={
												topUser[0].avatar === undefined || topUser[0].userId === undefined
													? userLogoDiscord
													: `https://cdn.discordapp.com/avatars/${topUser[0].userId}/${topUser[0].avatar}.webp`
											}
											alt="avatar uzytkownika"
											className="w-32 rounded-full border-2 border-yellow-300"
										/>
									</div>
									<div className="">
										<h2 className="text-2xl py-2 text-orange-custom font-semibold">{topUser[0].username}</h2>
										<p>{topUser[0].templateCount} szablonów</p>
									</div>
								</div>
							</a>
						)}
						{topUser[1] && (
							<a href={`/user/${topUser[1].userId}`}>
								<div className="item flex flex-col items-center bg-transparent-number w-64 py-5 h-64 text-center rounded-lg relative scale-105">
									<span className="material-symbols-outlined absolute top-[-15%] left-1/2 translate-x-[-50%] text-5xl text-silver">
										trophy
									</span>
									<div className="">
										<img
											src={
												topUser[1].avatar === undefined || topUser[1].userId === undefined
													? userLogoDiscord
													: `https://cdn.discordapp.com/avatars/${topUser[1].userId}/${topUser[1].avatar}.webp`
											}
											alt="avatar uzytkownika"
											className="w-32 rounded-full border-2 border-silver"
										/>
									</div>
									<div className="">
										<h2 className="text-2xl py-2 text-orange-custom font-semibold">{topUser[1].username}</h2>
										<p>{topUser[1].templateCount} szablonów</p>
									</div>
								</div>
							</a>
						)}
					</div>
					<div
						className="flex justify-center items-center gap-1 mt-5 transition-colors  hover:text-orange-custom cursor-pointer"
						onClick={() => setSearchPopup(true)}>
						<p className="text-lg ">Wyszukaj użytkownika</p>
						<span className="material-symbols-outlined ">trending_flat</span>
					</div>
				</section>
			</main>
			<Footer />
		</>
	)
}
