import { Link, useNavigate } from "react-router-dom"
import Logo from "../../img/Logo.png"
import { useState, useEffect } from "react"
import { getDatabase, get, ref, update, onValue, remove, limitToLast, query, set } from "firebase/database"
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage"
import "../../database"
import userLogoDiscord from "../../img/user-logo.webp"
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	AreaChart,
	Area,
	PieChart,
	Pie,
	Sector,
	Cell,
} from "recharts"
import toast, { Toaster } from "react-hot-toast"
import Loader from "../hook/Loader"
import { Tooltip as ReactToolTip } from "react-tooltip"
import CountUp from "react-countup"
import { LoaderAnimation } from "../hook/LoaderAnimation"
import useCheckToken from "../hook/checkTokenHook"

const db = getDatabase()
const event = new Date()
const options = {
	year: "numeric",
	month: "numeric",
	day: "numeric",
}

function TopNavPanel({ sidebar, setSidebar, isUserLogin, coins }) {
	return (
		<>
			{sidebar && (
				<div className="max-md:fixed max-md:top-0 max-md:left-0 max-md:w-full max-md:h-full max-md:bg-black-transparent max-md:overflow-y-hidden max-md:z-50"></div>
			)}
			<div className="w-full bg-dashboard-custom  flex items-center z-50   h-[40px]  relative">
				<div className="flex items-center">
					<a href="/" className="flex items-center">
						<img src={Logo} alt="logo templates" className="max-w-12 h-auto m-0" />
						<h2 className="text-xl m-0">
							Szablony<span className="text-orange-custom">Polska</span>
						</h2>
					</a>
					<button onClick={() => setSidebar(!sidebar)}>
						<span class="material-symbols-outlined ml-5 text-3xl">{sidebar ? "menu_open" : "menu"}</span>
					</button>
					<p className="flex items-center gap-1 ml-2 max-md:hidden">
						Masz
						<span className="text-orange-custom font-black">{coins === "" ? "0" : coins}</span> monet
					</p>
				</div>
				<div className="ml-auto mr-2 text-lg">
					<span className="max-md:hidden">Zalogowano jako:</span>{" "}
					<span className="text-orange-custom">{isUserLogin}</span>
				</div>
			</div>
		</>
	)
}

export function SidebarPanel({ sidebar, activeCategory, setActiveCategory, permission, coins }) {
	const handleCategoryClick = category => {
		setActiveCategory(category)
	}

	function logout() {
		localStorage.removeItem("token")
		window.location.href = "/"
	}

	return (
		<>
			<div
				className={`bg-dashboard-custom menu-mobile  h-[calc(100vh-40px)]  py-2   max-md:z-50 shrink-0 max-md:w-62 overflow-y-auto transition-all overflow-x-hidden  ${
					!sidebar ? "w-12 h-[calc(100vh-40px)]" : "w-52 px-2 max-md:absolute h-[calc(100vh-40px)]"
				}`}>
				<div className="flex flex-col justify-between h-full">
					<div className="mt-2 flex-grow">
						<div
							className={`flex gap-2 items-center text-lg hover:bg-hover-main-color-popup py-1 px-1 rounded-lg cursor-pointer ${
								activeCategory === "Dashboard" ? "text-orange-custom" : ""
							} ${permission ? "block" : "hidden"}`}
							onClick={() => handleCategoryClick("Dashboard")}>
							<span className={`material-symbols-outlined text-3xl ${!sidebar ? "text-center w-full" : ""} `}>
								query_stats
							</span>
							<p className={`${!sidebar ? "hidden" : "block"}`}>Dashboard</p>
						</div>
						<div
							className={`flex gap-2 items-center text-lg hover:bg-hover-main-color-popup py-1 px-1 rounded-lg cursor-pointer ${
								activeCategory === "Stats" ? "text-orange-custom" : ""
							}`}
							onClick={() => handleCategoryClick("Stats")}>
							<span className={`material-symbols-outlined text-3xl ${!sidebar ? "text-center w-full" : ""} `}>
								bar_chart_4_bars
							</span>
							<p className={`${!sidebar ? "hidden" : "block"}`}>Statystyki</p>
						</div>
						<div
							className={`flex gap-2 items-center text-lg hover:bg-hover-main-color-popup py-1 px-1 rounded-lg cursor-pointer ${
								activeCategory === "ProfileServer" ? "text-orange-custom" : ""
							}`}
							onClick={() => handleCategoryClick("ProfileServer")}>
							<span className={`material-symbols-outlined text-3xl ${!sidebar ? "text-center w-full" : ""} `}>dns</span>
							<p className={`${!sidebar ? "hidden" : "block"}`}>Profil serwera</p>
						</div>
						<div
							className={`flex gap-2 items-center text-lg hover:bg-hover-main-color-popup py-1 px-1 rounded-lg cursor-pointer ${
								activeCategory === "API" ? "text-orange-custom" : ""
							}`}
							onClick={() => handleCategoryClick("API")}>
							<span className={`material-symbols-outlined text-3xl ${!sidebar ? "text-center w-full" : ""} `}>api</span>
							<p className={`${!sidebar ? "hidden" : "block"}`}>API</p>
						</div>
						<div
							className={`flex gap-2 items-center text-lg hover:bg-hover-main-color-popup py-1 px-1 rounded-lg cursor-pointer ${
								activeCategory === "Support" ? "text-orange-custom" : ""
							}`}
							onClick={() => handleCategoryClick("Support")}>
							<span className={`material-symbols-outlined text-3xl ${!sidebar ? "text-center w-full" : ""} `}>
								support
							</span>
							<p className={`${!sidebar ? "hidden" : "block"}`}>Support</p>
						</div>
						<div
							className={`flex gap-2 items-center text-lg hover:bg-hover-main-color-popup py-1 px-1 rounded-lg cursor-pointer ${
								permission ? "block" : "hidden"
							}`}>
							<span className={`material-symbols-outlined text-3xl ${!sidebar ? "text-center w-full" : ""} `}>
								report
							</span>
							<p className={`${!sidebar ? "hidden" : "block"}`}>Zgłoszenia</p>
						</div>
						<div
							className={`flex gap-2 items-center text-lg hover:bg-hover-main-color-popup py-1 px-1 rounded-lg cursor-pointer ${
								activeCategory === "MenageAccounts" ? "text-orange-custom" : ""
							} ${permission ? "block" : "hidden"}`}
							onClick={() => handleCategoryClick("MenageAccounts")}>
							<span className={`material-symbols-outlined text-3xl ${!sidebar ? "text-center w-full" : ""} `}>
								manage_accounts
							</span>
							<p className={`${!sidebar ? "hidden" : "block"}`}>Użytkownicy</p>
						</div>

						<div
							className={`flex gap-2 items-center text-lg hover:bg-hover-main-color-popup py-1 px-1 rounded-lg cursor-pointer ${
								activeCategory === "MenageTemplates" ? "text-orange-custom" : ""
							} ${permission ? "block" : "hidden"}`}
							onClick={() => handleCategoryClick("MenageTemplates")}>
							<span className={`material-symbols-outlined text-3xl ${!sidebar ? "text-center w-full" : ""} `}>
								folder_managed
							</span>
							<p className={`${!sidebar ? "hidden" : "block"}`}>Szablony</p>
						</div>
					</div>
					<div className="py-2 cursor-pointer flex flex-col mt-3">
						<p className={`text-lg md:hidden ${!sidebar ? "hidden" : "block"}`}>
							Zdobyłeś <span className="text-orange-custom font-black">{coins === "" ? "0" : coins}</span> monet
						</p>
						<div className="flex  items-center justify-start">
							<span
								className={`material-symbols-outlined text-3xl text-red-500 ${
									!sidebar ? "text-center w-full px-2" : ""
								} `}>
								logout
							</span>
							<p className={`${!sidebar ? "hidden" : "block"} text-lg`} onClick={logout}>
								Wyloguj się
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

function Dashboard({ loader, activeCategory, sidebar }) {
	const [templates, setTemplates] = useState([])
	const [allTemplates, setAllTemplates] = useState([])
	const [stat, setStat] = useState([])
	const [user, setUser] = useState([])
	const [account, setAccount] = useState([])
	const [loaders, setLoaders] = useState(true)
	const [lastReport, setLastReport] = useState([])
	const [scanInfos, setScanInfo] = useState([])

	async function getData() {
		const templateBox = query(ref(db, "Template/box"), limitToLast(5))
		const userRef = ref(db, "User/")
		const stats = ref(db, "Stats/")
		const accounts = ref(db, `Accounts/`)
		const report = query(ref(db, `Report/box/`), limitToLast(5))
		const allTemplatesRef = ref(db, `Template/box`)
		const lastScanTemplate = query(ref(db, `ScanInfo/templates`), limitToLast(1))
		const lastScanUser = query(ref(db, `ScanInfo/user`), limitToLast(1))

		try {
			const templateSnapshot = await get(templateBox)
			const statsSnapshot = await get(stats)
			const userSnapshot = await get(userRef)
			const accountSnapshot = await get(accounts)
			const reportSnapshot = await get(report)
			const allTemplateSnapshot = await get(allTemplatesRef)
			const lastScanTemplateSnapshot = await get(lastScanTemplate)
			const lastScanUserSnapshot = await get(lastScanUser)

			const scanInfo = {
				lastScanTemplate: lastScanTemplateSnapshot.val(),
				lastScanUser: lastScanUserSnapshot.val(),
			}

			setScanInfo(scanInfo)

			setTemplates(Object.values(templateSnapshot.val()))
			setAllTemplates(Object.values(allTemplateSnapshot.val()))
			setStat(Object.values(statsSnapshot.val()))
			setUser(Object.values(userSnapshot.val()))
			setAccount(Object.values(accountSnapshot.val()))
			setLastReport(Object.values(reportSnapshot.val()))
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		if (loader) return
		if (activeCategory !== "Dashboard") return

		getData()

		return () => setLoaders(true)
	}, [activeCategory, loader])

	useEffect(() => {
		if (allTemplates.length > 0) {
			setLoaders(false)
		}
	}, [allTemplates])

	const reservedTemplate = allTemplates && [...allTemplates].reverse()
	const accountReverse = account && [...account].reverse()

	const countByDate =
		allTemplates &&
		reservedTemplate.reduce((acc, template) => {
			const date = template.dateCreate
			if (!acc[date]) {
				acc[date] = { date: date, count: 0 }
			}
			acc[date].count += 1

			return acc
		}, {})

	const checkDateCreateAccounts =
		account &&
		accountReverse.reduce((acc, user) => {
			const date = user.dateCreate

			if (!acc[date]) {
				acc[date] = { date, count: 0 }
			}

			acc[date].count += 1

			return acc
		}, {})

	const chartData = Object.values(countByDate)
	const userCreateAccountArray = Object.values(checkDateCreateAccounts).reverse()

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<>
					<div className="bg-white text-black p-2 rounded-lg">
						<p>Liczba: {payload[0].payload.count}</p>
						<p>Data: {payload[0].payload.date}</p>
					</div>
				</>
			)
		}
	}

	return (
		<>
			<LoaderAnimation loaders={loaders} />
			<div className={`${loaders ? "hidden" : "flex"} show flex-col grow-0 px-10 max-lg:px-5 mt-5 max-md:px-2 pb-5`}>
				<div className="dashboard-stats">
					<div className="flex items-center gap-2">
						<span className="material-symbols-outlined bg-orange-custom py-1 px-1 rounded-lg">dashboard</span>
						<h2 className="text-xl font-semibold">Dashboard</h2>
					</div>
					<div className="stats mt-5 flex gap-5 flex-wrap">
						<div className="stat py-5 px-5 w-[23rem] bg-dashboard-custom rounded-lg flex items-center gap-5">
							<span className="material-symbols-outlined text-[4rem] bg-blue-500 px-2 py-1 rounded-lg ">category</span>
							<div className="">
								<h3 className="my-1 text-lg">Wszystkie szablony</h3>
								<p className="text-3xl font-medium">
									<CountUp start={loaders} end={allTemplates.length} duration={5} />
								</p>
							</div>
						</div>
						<div className="stat py-5 px-5 w-[23rem] bg-dashboard-custom rounded-lg flex items-center gap-5 scale-105 max-md:scale-100">
							<span className="material-symbols-outlined text-[4rem] bg-orange-custom px-2 py-1 rounded-lg ">
								report
							</span>
							<div className="">
								<h3 className="my-1 text-lg">Kliknięć "Uzyj szablon"</h3>

								<p className="text-3xl font-black">
									<CountUp start={loaders} end={stat[0]} duration={5} />
								</p>
							</div>
						</div>
						<div className="stat py-5 px-5 w-[23rem] bg-dashboard-custom rounded-lg flex items-center gap-5 scale-105 max-md:scale-100">
							<span className="material-symbols-outlined text-[4rem] bg-orange-custom px-2 py-1 rounded-lg ">scan</span>
							<div className="">
								<h3 className="font-black text-lg">Ostatnie skanowanie</h3>
								<div className="flex items-center">
									{scanInfos.lastScanTemplate &&
										Object.values(scanInfos.lastScanTemplate).map(info => (
											<>
												<p>Szablony:</p>
												<span className="ml-1">{info.lastScanDate}</span>
											</>
										))}
								</div>
								<div className="flex items-center">
									{scanInfos.lastScanTemplate &&
										Object.values(scanInfos.lastScanUser).map(info => (
											<>
												<p>Użytkownicy:</p>
												<span className="ml-1">{info.lastScanDate}</span>
											</>
										))}
								</div>
							</div>
						</div>
						<div className="stat py-5 px-5 w-[23rem] bg-dashboard-custom rounded-lg flex items-center gap-5">
							<span className="material-symbols-outlined text-[4rem] bg-blue-500 px-2 py-1 rounded-lg ">person</span>
							<div className="">
								<h3 className="my-1 text-lg">Osób dodających szablonów</h3>
								<p className="text-3xl font-medium">
									<CountUp start={loaders} end={user.length} duration={5} />
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="dashboard-history mt-5">
					<div className="flex w-full max-lg:flex-col items-start gap-5 mt-5 ">
						<div className="w-1/2 h-96 bg-dashboard-custom p-4 rounded-lg max-lg:w-full">
							<p>Wysłane ostatnie szablony (20 dni)</p>
							<div className="py-2">
								<ResponsiveContainer height="95%" width="100%">
									<BarChart data={chartData.splice(0, 20)}>
										<XAxis dataKey="date" />
										<CartesianGrid strokeDasharray="3 3" />
										<Tooltip content={<CustomTooltip />} />
										<YAxis orientation="right" />
										<Bar dataKey="count" fill="#11998e" />
									</BarChart>
								</ResponsiveContainer>
							</div>
						</div>
						<div className="w-1/2 h-96 bg-dashboard-custom p-4 rounded-lg max-lg:w-full">
							<p>Tworzenie kont przez użytkowników</p>
							<div className="py-2">
								<ResponsiveContainer height="95%" width="100%">
									<AreaChart data={userCreateAccountArray.splice(0, 15)}>
										<defs>
											<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
												<stop offset="5%" stopColor="#11998e" stopOpacity={0.8} />
												<stop offset="95%" stopColor="#11998e" stopOpacity={0} />
											</linearGradient>
											<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
												<stop offset="5%" stopColor="#11998e" stopOpacity={0.8} />
												<stop offset="95%" stopColor="#11998e" stopOpacity={0} />
											</linearGradient>
										</defs>
										<XAxis dataKey="date" />
										<YAxis orientation="right" />
										<CartesianGrid strokeDasharray="3 3" />
										<Tooltip content={<CustomTooltip />} />
										<Area dataKey="count" stroke="#11998e" fill="url(#colorUv)" />
									</AreaChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
					<div className="flex w-full max-lg:flex-col items-start gap-5 mt-5">
						<div className="overflow-x-auto shadow-md sm:rounded-lg w-1/2 max-lg:w-full">
							<table className="w-full text-sm text-left rtl:text-right ">
								<caption className="p-5 text-lg font-semibold text-left rtl:text-right  text-white bg-dashboard-custom">
									Ostatnie dodane szablony
								</caption>
								<thead className="text-xs  uppercase  bg-table-custom text-gray-400">
									<tr>
										<th scope="col" className="px-6 py-3">
											ID
										</th>
										<th scope="col" className="px-6 py-3">
											Nazwa
										</th>
										<th scope="col" className="px-6 py-3">
											Kategoria
										</th>
										<th scope="col" className="px-6 py-3">
											Link
										</th>

										<th scope="col" className="px-6 py-3">
											<span className="sr-only">Edit</span>
										</th>
									</tr>
								</thead>
								<tbody>
									{templates.map((template, index) => (
										<tr className="border-b border-table-custom bg-dashboard-custom even:bg-table-custom" key={index}>
											<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
												{template.ID}
											</th>
											<td className="px-6 py-4">{template.title}</td>
											<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
												{template.categories}
											</th>
											<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
												{template.link}
											</th>

											<td className="px-6 py-4 text-right">
												<a href="#" className="font-medium text-blue-500 hover:underline">
													Przejdź
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<div className="overflow-x-auto shadow-md sm:rounded-lg w-1/2 max-lg:w-full">
							<table className="w-full text-sm text-left rtl:text-right ">
								<caption className="p-5 text-lg font-semibold text-left rtl:text-right  text-white bg-dashboard-custom">
									Ostatnie zgłoszenia szablonów
								</caption>
								<thead className="text-xs  uppercase  bg-table-custom text-gray-400">
									<tr>
										<th scope="col" className="px-6 py-3">
											ID szablonu
										</th>
										<th scope="col" className="px-6 py-3">
											Powód
										</th>

										<th scope="col" className="px-6 py-3">
											<span className="sr-only">Edit</span>
										</th>
									</tr>
								</thead>
								<tbody>
									{lastReport.reverse().map((template, index) => (
										<tr className="border-b border-table-custom bg-dashboard-custom even:bg-table-custom" key={index}>
											<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
												{template.id}
											</th>
											<td className="px-6 py-4">{template.reason}</td>

											<td className="px-6 py-4 text-right">
												<a
													href={`https://szablonydiscord.pl/templates/${template.id}`}
													className="font-medium text-blue-500 hover:underline">
													Przejdź
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

function MenageUsers(activeCategory) {
	const [data, setData] = useState({
		user: [],
		account: [],
	})
	const [selectRole, setSelectedRole] = useState("")
	const [popup, setPopup] = useState(null)
	const [currentRole, setCurrentRole] = useState("")
	const [visibleTemplate, setVisibleTemplate] = useState(10)

	async function loadInformation() {
		const userRef = ref(db, "User/")
		const accountRef = ref(db, "Accounts/")

		await Promise.all([get(userRef), get(accountRef)]).then(([userSnapshot, accountSnapshot]) => {
			const user = Object.values(userSnapshot.val())
			const account = Object.values(accountSnapshot.val())

			setData(prev => ({
				...prev,
				user,
				account,
			}))
		})
	}

	useEffect(() => {
		if (activeCategory.activeCategory === "MenageAccounts") {
			loadInformation()
		}
	}, [activeCategory])

	function handleClickRole(role) {
		setSelectedRole(role)

		const userAccount = ref(db, `User/${popup}`)

		const updates = {
			role: role,
		}

		onValue(userAccount, snapshot => {
			const info = Object.values(snapshot.val())
			setCurrentRole(info[1])
		})

		update(userAccount, updates)
	}

	function SaveUser() {
		loadInformation()
		setPopup(null)
	}

	return (
		<>
			{popup !== null && (
				<div className="fixed top-0 left-0 w-full h-full bg-black-transparent overflow-y-hidden z-50"></div>
			)}
			<div
				className={`${
					popup !== null ? "block" : "hidden"
				}    bg-popup-custom fixed top-2/4 left-2/4 translate-x-[-50%] translate-y-[-50%] w-96 px-4 py-3 rounded-xl max-xl:w-[50%] 
				max-md:min-w-[80%] overflow-auto z-50`}>
				<div className="popup-main flex justify-between text-xl">
					<h2>Edytowanie użytkownika</h2>
					<button>
						<span
							className="material-symbols-outlined"
							onClick={() => {
								setPopup(null)
							}}>
							close
						</span>
					</button>
				</div>
				<hr />
				<div className="items">
					<p className="mt-2 text-lg">ID użytkownika</p>
					<input type="text" value={popup} className=" w-full py-1 px-1 rounded-lg bg-input-custom" readOnly />

					<div className="role-add my-2">
						<p className="text-xl">Wybierz rolę którą chcesz dodać</p>
						<div className="flex gap-2 mt-1">
							<div
								className={`bg-red-700 w-fit px-2 rounded-full cursor-pointer ${
									selectRole === "Administrator" ? "scale-105" : ""
								}`}
								onClick={() => handleClickRole("Administrator")}>
								<p>Administrator</p>
							</div>
							<div
								className={`bg-green-600 w-fit px-2 rounded-full cursor-pointer ${
									selectRole === "Moderator" ? "scale-105" : ""
								}`}
								onClick={() => handleClickRole("Moderator")}>
								<p>Moderator</p>
							</div>
							<div
								className={`bg-yellow-600 w-fit px-2 rounded-full cursor-pointer ${
									selectRole === "Support" ? "scale-105" : ""
								}`}
								onClick={() => handleClickRole("Support")}>
								<p>Support</p>
							</div>
						</div>
						{currentRole !== "" && <p className="mt-2">Aktualna rola: {currentRole}</p>}
					</div>
					<div className="my-2">
						<p className="text-xl">Pozostałe</p>
						<button className="bg-red-500 px-2 rounded-lg">Usuń użytkownika</button>
						<button className="bg-red-500 px-2 rounded-lg mx-2" onClick={() => handleClickRole("")}>
							Usuń role użytkownika
						</button>
					</div>
					<button
						className="bg-orange-custom w-full text-xl rounded-lg mt-2"
						onClick={() => {
							SaveUser()
						}}>
						Zaktualizuj
					</button>
				</div>
			</div>
			<div className="px-10 max-lg:px-5 mt-5 max-md:px-2">
				<h2 className="text-2xl font-semibold">Zarządzanie użytkownikami</h2>
				<div className="flex gap-2 mt-2">
					<button className="text-orange-custom">Profile szablonowe</button>
					<button>Profile kont</button>
				</div>
				<div className="mt-10  flex flex-wrap gap-5">
					<div className="stat py-5 px-5 w-96 bg-dashboard-custom rounded-lg flex items-center gap-5">
						<span className="material-symbols-outlined text-[4rem] bg-blue-500 px-2 py-1 rounded-lg ">person</span>
						<div className="">
							<h3 className="my-1 text-lg">Osób dodających szablonów</h3>
							<p className="text-3xl font-medium">{data.user.length}</p>
						</div>
					</div>
					<div className="stat py-5 px-5 w-96 bg-dashboard-custom rounded-lg flex items-center gap-5">
						<span className="material-symbols-outlined text-[4rem] bg-orange-custom px-2 py-1 rounded-lg ">
							account_circle
						</span>
						<div className="">
							<h3 className="my-1 text-lg">Kont użytkowników</h3>
							<p className="text-3xl font-medium">{data.account.length}</p>
						</div>
					</div>
				</div>
				<div className="bg-dashboard-custom min-w-[15rem] max-w-[75rem] py-5 px-5 mt-12 rounded-lg max-sm:overflow-x-scroll">
					<div className="flex gap-1 items-center">
						<h2 className="text-2xl font-semibold">Osoby dodające szablony</h2>
						<button>
							<span className="material-symbols-outlined" onClick={() => loadInformation()}>
								refresh
							</span>
						</button>
					</div>
					<div className="flex items-center gap-1 my-2">
						<div className="w-2 h-2 bg-red-700 rounded-full"></div> <p>Administrator,</p>
						<div className="w-2 h-2 bg-green-600 rounded-full"></div> <p>Moderator,</p>
						<div className="w-2 h-2 bg-yellow-600 rounded-full"></div> <p>Support</p>
					</div>
					<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
						<table className="w-full text-sm text-left rtl:text-right  text-gray-300">
							<thead className="text-xs text-gray-300 uppercase  bg-[#32343b] ">
								<tr>
									<th scope="col" className="px-6 py-3">
										Użytkownik
									</th>
									<th scope="col" className="px-6 py-3">
										ID
									</th>
									<th scope="col" className="px-6 py-3">
										Liczba szablonów
									</th>

									<th scope="col" className="px-6 py-3">
										<span className="sr-only">Edit</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{data.user.slice(0, visibleTemplate).map((user, index) => (
									<tr className=" border-b  border-gray-700 even:bg-[#32343b]" key={index}>
										<th className="flex gap-1 items-center px-6 py-4">
											<img
												src={
													user.avatar === undefined || user.userId === undefined
														? userLogoDiscord
														: `https://cdn.discordapp.com/avatars/${user.userId}/${user.avatar}.webp`
												}
												alt="logo discord user"
												className="w-5 rounded-full"
											/>
											{user.username}
											{user.role === "Administrator" && <div className="w-2 h-2 bg-red-700 rounded-full"></div>}
											{user.role === "Support" && <div className="w-2 h-2 bg-yellow-500 rounded-full"></div>}
											{user.role === "Moderator" && <div className="w-2 h-2 bg-green-500 rounded-full"></div>}
										</th>
										<td className="px-6 py-4">{user.userId}</td>
										<td className="px-6 py-4">{user.templates === undefined ? "0" : user.templates.length}</td>

										<td className="px-6 py-4 text-right">
											<button
												className="font-medium text-blue-500 hover:underline"
												onClick={() => setPopup(user.userId)}>
												Edytuj
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<button className={`my-2 text-center text-xl `} onClick={() => setVisibleTemplate(visibleTemplate + 10)}>
							Pokaż więcej
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

function MenageTemplates(activeCategory) {
	const [loaders, setLoaders] = useState(true)
	const [data, setData] = useState([])
	const [search, setSearch] = useState("")
	const [visibleTemplate, setVisibleTemplate] = useState(30)

	const templateRef = ref(db, "Template/box/")

	async function loadTemplate() {
		await get(templateRef).then(snapshot => {
			const data = Object.values(snapshot.val())
			setData(data)
		})
	}

	useEffect(() => {
		loadTemplate()

		if (data.length > 1) {
			setTimeout(() => {
				setLoaders(false)
			}, 200)
		}

		return () => setLoaders(true)
	}, [activeCategory.activeCategory === "MenageTemplates"])

	useEffect(() => {
		if (search !== "") return setVisibleTemplate(data.length)
	}, [search])

	function deleteTemplate(id) {
		const templateRefDelete = ref(db, "Template/box/" + id)

		remove(templateRefDelete)
		loadTemplate()
	}

	return (
		<>
			<LoaderAnimation loaders={loaders} />
			<div className={`${loaders ? "hidden" : "block"} show px-10 mt-5 max-md:mx-2 max-md:px-2`}>
				<h2 className="text-2xl font-semibold">Dodane szablony użytkowników</h2>
				<div className="mt-10 flex gap-2 flex-wrap">
					<div className="stat py-5 px-5 w-96 bg-dashboard-custom rounded-lg flex items-center gap-5">
						<span className="material-symbols-outlined text-[4rem] bg-blue-500 px-2 py-1 rounded-lg ">person</span>
						<div className="">
							<h3 className="my-1 text-lg">Liczba szablonów</h3>
							<p className="text-3xl font-medium">{data.length}</p>
						</div>
					</div>
				</div>
				<div className="bg-dashboard-custom min-w-[15rem] max-w-[75rem] py-5 px-5 mt-12 rounded-lg max-lg:overflow-x-scroll">
					<div className="flex gap-1 items-center">
						<h2 className="text-2xl font-semibold">Szablony użytkowników</h2>
						<button>
							<span className="material-symbols-outlined" onClick={() => loadTemplate()}>
								refresh
							</span>
						</button>
					</div>
					<table className="w-full text-sm text-left rtl:text-right  text-gray-300">
						<caption className="w-fit">
							<input
								type="text"
								className="my-2 bg-[#32343b] py-2 px-2 rounded-lg"
								placeholder="wyszukaj pod ID"
								onChange={e => setSearch(e.target.value.toLowerCase())}
							/>
						</caption>
						<thead className="text-xs text-gray-300 uppercase  bg-[#32343b] ">
							<tr>
								<th scope="col" className="px-6 py-3">
									Nazwa
								</th>
								<th scope="col" className="px-6 py-3">
									ID
								</th>
								<th scope="col" className="px-6 py-3">
									link
								</th>

								<th scope="col" className="px-6 py-3">
									<span className="sr-only">Edit</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{data.slice(0, visibleTemplate).map((template, index) => (
								<tr
									className={` border-b  border-gray-700 even:bg-[#32343b] ${
										template.ID.toString().indexOf(search) !== -1 ? "visible" : "hidden"
									}`}
									key={index}>
									<th className="flex gap-1 items-center px-6 py-4">{template.title}</th>
									<td className="px-6 py-4">{template.ID}</td>
									<td className="px-6 py-4">{template.link}</td>

									<td className="px-6 py-4 flex gap-1 items-center text-right">
										<button className="font-medium text-orange-custom hover:underline">
											<span class="material-symbols-outlined">edit</span>
										</button>
										<button className="">
											<span
												className="material-symbols-outlined text-red-500"
												onClick={e => deleteTemplate(template.ID)}>
												delete
											</span>
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<button className={`my-2 text-center text-xl `} onClick={() => setVisibleTemplate(visibleTemplate + 30)}>
						Pokaż więcej
					</button>
				</div>
			</div>
		</>
	)
}

function ProfileServer({ isUserLogin, activeCategory }) {
	const [create, setCreate] = useState(false)
	const [loaders, setLoaders] = useState(true)
	const [errProfile, setErrProfile] = useState(false)
	const [loaderClick, setLoaderClick] = useState(false)
	const [profileData, setProfileData] = useState([])
	const [name, setName] = useState("")
	const [link, setLink] = useState("")
	const [image, setImage] = useState(null)
	const [err, setErr] = useState("")
	const [userServer, setUserServer] = useState([])
	const storage = getStorage()
	const ServerUserRef = ref(db, `Accounts/${isUserLogin}/ProfileServer/`)
	const serverID = Date.now()

	function reset() {
		setErr("")
		setCreate(false)
		setLoaderClick(false)
		setName("")
		setImage(null)
		setLink("")
	}

	async function sendForm(e) {
		setLoaderClick(true)
		e.preventDefault()
		const checkServerLink = await fetch(
			`https://discord.com/api/v10/invites/${link.split("https://discord.gg")[1]}?with_counts=true`
		)
		const checkServerData = await checkServerLink.json()
		if (name === "") return setErr("Uzupełnij nazwe serwera"), setLoaderClick(false)
		if (name.length > 15) return setErr("Nazwa serwera jest za długa (15 znaków)"), setLoaderClick(false)
		if (link === "") return setErr("Uzupełnij link do serwera"), setLoaderClick(false)
		if (!link.includes("https://discord.gg/"))
			return setErr("Link do serwera jest nie prawidłowy."), setLoaderClick(false)
		if (checkServerData.code === 10006) return setErr("Zaproszenie do serwera nie działa"), setLoaderClick(false)
		if (userServer.length >= 3) return setErr("Możesz mieć maksymalnie 3 serwery"), setLoaderClick(false)
		if (!image) return setErr("Wybierz avatar"), setLoaderClick(false)

		const storageRefs = storageRef(storage, "ProfileImages" + image.name)

		uploadBytes(storageRefs, image).then(snapshot => {
			getDownloadURL(snapshot.ref).then(downloadURL => {
				set(ref(db, `Accounts/${isUserLogin}/ProfileServer/` + serverID), {
					name: name,
					avatar: downloadURL,
					link: link,
					id: serverID,
				})

				set(ref(db, `ProfileServer/` + serverID), {
					id: serverID,
					name: name,
					owner: isUserLogin,
					link: link,
					userSever: [],
					templatesCount: 0,
					avatar: downloadURL,
					dateCreate: event.toLocaleDateString("pl-PL", options),
				})
				reset()
			})
		})
	}

	function updateListServer() {
		onValue(ServerUserRef, snapshot => {
			const snapshotVal = snapshot.val()
			if (snapshotVal) {
				const data = Object.values(snapshot.val())
				setUserServer(data)
			}
		})

		get(ServerUserRef).then(snapshot => {
			const snapshotVal = snapshot.val()
			if (snapshotVal) {
				const data = Object.values(snapshot.val())
				setUserServer(data)
			}
		})
	}

	useEffect(() => {
		updateListServer()

		setTimeout(() => {
			if (userServer) {
				setLoaders(false)
			}
		}, 300)

		return () => reset(), setLoaders(true)
	}, [activeCategory === "ProfileServer"])

	async function deleteServer(serverID) {
		const serverUserDeleteRef = ref(db, `Accounts/${isUserLogin}/ProfileServer/` + serverID)
		const serverRef = ref(db, `ProfileServer/${serverID}`)
		const apiUser = ref(db, `Accounts/${isUserLogin}/API`)

		const api = await get(apiUser)

		if (api.val() !== null) {
			const searchApi = Object.values(api.val()).filter(api => parseInt(api.id) === serverID)
			const data = [{ serverID: serverID, apiKey: searchApi.map(api => api.api)[0] }]

			if (searchApi.length >= 1) return setErrProfile(true), setProfileData(data)
		} else {
			remove(serverUserDeleteRef)
			updateListServer()
			remove(serverRef)
			if (userServer.length === 1) return setUserServer([])
		}
	}

	function acceptDeleteServer() {
		const apiRef = ref(db, `API/${profileData[0].apiKey}`)
		const apiAccountRef = ref(db, `Accounts/${isUserLogin}/API/${profileData[0].apiKey}`)
		const profileAccountsRef = ref(db, `Accounts/${isUserLogin}/ProfileServer/${profileData[0].serverID}`)
		const serverRef = ref(db, `ProfileServer/${profileData[0].serverID}`)

		remove(serverRef)
		remove(apiRef)
		remove(apiAccountRef)
		remove(profileAccountsRef)
		updateListServer()
		setErrProfile(false)
		if (userServer.length === 1) return setUserServer([])
	}

	return (
		<>
			<LoaderAnimation loaders={loaders} />
			{errProfile && (
				<div className="fixed top-0 left-0 w-full h-full bg-black-transparent overflow-y-hidden z-50"></div>
			)}
			<div
				className={`${
					errProfile ? "block" : "hidden"
				} show w-96 max-md:w-11/12 bg-popup-custom absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-5 py-5 rounded-lg text-center z-50`}>
				<div className="flex justify-center w-full -translate-y-14">
					<span className="material-symbols-outlined text-5xl bg-red-500 px-3 py-3 rounded-full">close</span>
				</div>
				<div className="-translate-y-8">
					<h2 className="text-2xl">Ostrzeżenie!</h2>
					<p className="mt-2">
						Serwer który chcesz usunąć jest przypisany do klucza API, czy chcesz usunąć klucz API i serwer?
					</p>
				</div>
				<div className="flex gap-1 w-full">
					<button className="bg-red-500 w-1/2 py-2" onClick={acceptDeleteServer}>
						Potwierdź
					</button>
					<button className="bg-orange-custom w-1/2 py-2" onClick={() => setErrProfile(false)}>
						Anuluj
					</button>
				</div>
			</div>
			<div className={`${loaders ? "hidden" : "block"} show mt-10 px-10 max-md:px-2`}>
				<h2 className="text-3xl font-black">Tworzenie profilu serwera</h2>
				<div className="min-w-32 max-w-96 bg-dashboard-custom px-5 py-5 rounded-lg mt-5 max-md:px-2">
					<div className={`${create === true ? "hidden" : "block"}`}>
						<p className="text-xl ">Co otrzymujesz dzięki profilu serwera?</p>
						<ul className="mt-5">
							<li className="flex items-center gap-1 my-2">
								<span className="material-symbols-outlined">task_alt</span>
								Promocje twojego serwera na stronie
							</li>
							<li className="flex items-center gap-1 my-2">
								<span className="material-symbols-outlined">task_alt</span>
								Topke serwerów szablonowych
							</li>
							<li className="flex items-center gap-1 my-2">
								<span className="material-symbols-outlined">task_alt</span>
								Specjalna plakietka dla serwera
							</li>

							<li className="flex items-center gap-1 my-2">
								<span className="material-symbols-outlined">task_alt</span>
								Informacje o szablonie z którego serwera
							</li>
						</ul>

						<button
							className="py-1 px-2 bg-orange-custom mt-2  rounded-lg animate-pulse"
							onClick={() => setCreate(true)}>
							Zacznij tworzyć
						</button>
					</div>
					<div className={`${create !== false ? "block" : "hidden"}`}>
						<p className="text-xl">Konfigurowanie serwera</p>
						<form onSubmit={sendForm}>
							<div className="relative mt-5">
								<input
									type="text"
									value={name === "" ? "" : name}
									id="server-name"
									className="block px-2.5 py-2.5 w-full text-sm text-white bg-transparent rounded-lg border-[1px] border-white appearance-none    focus:outline-none focus:ring-0 focus:border-orange-custom peer border-1 "
									placeholder=" "
									onChange={e => {
										setName(e.target.value)
									}}
								/>
								<label
									htmlFor="server-name"
									className="absolute text-sm text-white  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-dashboard-custom px-2 peer-focus:px-2 peer-focus:text-orange-custom  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
									Nazwa serwera
								</label>
							</div>
							<div className="relative mt-2">
								<input
									type="text"
									id="server-link"
									value={link === "" ? "" : link}
									className="block px-2.5 py-2.5 w-full text-sm text-white bg-transparent rounded-lg border-[1px] border-white appearance-none    focus:outline-none focus:ring-0 focus:border-orange-custom peer border-1 "
									placeholder=" "
									onChange={e => {
										setLink(e.target.value)
									}}
								/>
								<label
									htmlFor="server-link"
									className="absolute text-sm text-white  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-dashboard-custom px-2 peer-focus:px-2 peer-focus:text-orange-custom  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
									Link do serwera
								</label>
							</div>
							<label className="block mt-2 my-1 text-sm font-medium text-white" for="file_input">
								Avatar Serwera
							</label>
							<input
								className="block w-full text-sm  text-white  rounded-lg cursor-pointer  focus:outline-none bg-gray-700 "
								id="file_input"
								type="file"
								accept="image/png, image/jpeg"
								onChange={e => {
									setImage(e.target.files[0])
								}}></input>
							<p className="mt-2 text-red-500">{err}</p>
							<div className="flex items-center gap-2 mt-2">
								<button className="py-1 px-2 bg-orange-custom  rounded-lg">Stwórz profil</button>
								<div className={`${loaderClick ? "block" : "hidden"}`}>
									<svg
										aria-hidden="true"
										class="w-6 h-6  animate-spin text-gray-600 fill-orange-custom"
										viewBox="0 0 100 100"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="currentColor"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentFill"
										/>
									</svg>
								</div>
							</div>
						</form>
					</div>
				</div>

				<h2 className="text-3xl font-black mt-5">Twoje serwery</h2>
				<p className={`${userServer.length === 0 ? "block" : "hidden"}`}>Nie masz dostępnych żadnych serwerów</p>
				<div className="flex flex-wrap gap-5">
					{userServer.map((server, index) => (
						<div
							className={`min-w-32 max-w-96 bg-dashboard-custom py-5 px-5 mt-2 rounded-lg h-36 ${
								userServer.length === 0 ? "hidden" : "flex"
							} flex-col justify-between`}
							key={index}>
							<div className="flex gap-2">
								<img src={server.avatar} alt="logo-server" className="w-12 rounded-full" />
								<div className="">
									<h2 className="text-xl font-semibold">{server.name}</h2>
									<p>{server.link}</p>
								</div>
							</div>
							<div className="flex gap-2">
								<a href={`/panel/${server.id}`} className="flex items-center gap-1">
									<span class="material-symbols-outlined text-xl">edit</span>
									Edytuj
								</a>
								<div
									className="flex items-center cursor-pointer transition-colors hover:text-red-500"
									onClick={() => deleteServer(server.id)}>
									<span class="material-symbols-outlined text-xl">delete</span>
									<p>Usuń</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	)
}

function Api({ isUserLogin, activeCategory, setActiveCategory }) {
	const [dataServer, setDataServer] = useState([])
	const [data, setData] = useState([])
	const [idServer, setServerID] = useState("")
	const [server, setServer] = useState("")
	const [err, setErr] = useState(false)
	const [loaders, setLoaders] = useState(true)
	const [apiReqCount, setApiReqCount] = useState([])
	const accRef = ref(db, `Accounts/${isUserLogin}/ProfileServer/`)
	const apiRef = ref(db, `Accounts/${isUserLogin}/API/`)
	const userRef = ref(db, `Accounts/${isUserLogin}`)
	const apiCountReq = ref(db, "API/")

	function updateListApi() {
		onValue(apiRef, snapshot => {
			if (snapshot.exists()) {
				setData(Object.values(snapshot.val()))
			} else {
				setData([])
			}
		})
	}

	useEffect(() => {
		onValue(accRef, snapshot => {
			snapshot.exists() ? setDataServer(Object.values(snapshot.val())) : setDataServer([])
		})
		onValue(apiCountReq, snapshot => {
			snapshot.exists() ? setApiReqCount(Object.values(snapshot.val())) : setApiReqCount([])
		})

		updateListApi()

		return () => setErr(false), setLoaders(true), updateListApi(), setServer(""), setServerID(""), setErr(false)
	}, [activeCategory === "API"])

	setTimeout(() => {
		if (data) {
			setLoaders(false)
		}
	}, 300)

	function sendData(e) {
		e.preventDefault()

		const repeatServer = data.some(api => api.id === idServer)
		if (data.length >= 3) return
		if (server === "") return toast.error("Musisz wpisać nazwe klucza API"), setErr(true)
		if (idServer === "" || server === "") return toast.error("Musisz wybrać serwer"), setErr(true)
		if (server.length >= 12) return toast.error("Nazwa API może mieć max 12 znaków."), setErr(true)
		if (repeatServer) return toast.error("Do jednego klucza API możesz mieć przypisany max 1 serwer"), setErr(true)

		setErr("")
		fetch(process.env.REACT_APP_CREATE_API_KEY, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ nameServer: server, serverID: idServer, user: isUserLogin }),
		})
		setErr(false)
		setServer("")
		setServerID("")
	}

	const reqAll = apiReqCount.map(key => key.reqCount)
	const reqUser = data.map(key => key.reqCount)

	const dates = [
		{ name: "Ilość API", apiLength: apiReqCount.length },
		{ name: "Suma requestów", requestCount: reqAll.reduce((cr, cur) => cr + cur, 0) },
		{ name: "Twoje requesty", yourRequest: reqUser.reduce((cr, cur) => cr + cur, 0) },
	]

	const SimpleLineChart = () => (
		<ResponsiveContainer width="100%" height={250}>
			<BarChart data={dates}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" />
				<YAxis orientation="right" />
				<Tooltip />
				<Legend />
				<Bar dataKey="apiLength" fill="#11998e" />
				<Bar dataKey="requestCount" fill="#196b64" />
				<Bar dataKey="yourRequest" fill="#17c0b2" />
			</BarChart>
		</ResponsiveContainer>
	)

	async function deleteApi(apiKey) {
		const accRefDel = ref(db, `API/${apiKey}`)
		const apiRefDel = ref(db, `Accounts/${isUserLogin}/API/${apiKey}`)
		const userSnap = await get(userRef)

		await Promise.all([remove(accRefDel), remove(apiRefDel)])
		update(userRef, { coins: userSnap.val().coins - 15 })
		updateListApi()
	}

	function statusAPI(apiKey, e, owner) {
		const updateRef = ref(db, `API/${apiKey}`)
		const updateUserRef = ref(db, `Accounts/${owner}/API/${apiKey}`)
		const isChecked = e.target.checked
		const updates = {
			status: isChecked ? "Aktywny" : "Nieaktywny",
		}

		Promise.all([update(updateRef, updates), update(updateUserRef, updates)])
	}

	const checkIsBlocked = data.some(isBlocked => isBlocked.status === "Zablokowany")

	function offDomain(e) {
		const isChecked = e.target.checked

		const checkChecked = isChecked ? "Wyłączony" : null

		data.forEach(key => {
			const apiRefs = ref(db, `Accounts/${isUserLogin}/API/${key.api}`)
			const apis = ref(db, `API/${key.api}`)

			Promise.all([update(apiRefs, { domain: checkChecked }), update(apis, { domain: checkChecked })])
		})
	}

	return (
		<>
			<LoaderAnimation loaders={loaders} />
			<div className={`${loaders ? "hidden" : "block"} show mt-10 px-10 max-md:px-2 pb-5`}>
				<h2 className="text-3xl font-black">Klucze API</h2>
				<div className="min-w-32 max-w-[65rem] border border-orange-custom px-4 py-3 text-orange-custom my-2">
					<p>
						Pamiętaj! Twoje API umożliwia dostęp do szablonów discord z naszej strony. Nie udostępniaj swojego klucza
						nikomu.
						<button className="underline" onClick={() => setActiveCategory("Support")}>
							Support
						</button>
					</p>
				</div>
				<div
					className={`${
						dataServer && dataServer.length !== 0 ? "hidden" : "block"
					} min-w-32 max-w-[65rem] border border-red-600 px-4 py-3 text-red-600 my-2`}>
					<p>Aby móc stworzyć klucz api musisz mieć stworzony profil serwera.</p>
				</div>
				<div
					className={`${
						data && data.length >= 3 ? "block" : "hidden"
					} min-w-32 max-w-[65rem] border border-yellow-600 px-4 py-3 text-yellow-600 my-2`}>
					<p>
						Możesz mieć maksymalnie wygenerowane 3 klucze API. Jeśli potrzbujesz wiecej skontaktuj się z właścicielem.
					</p>
				</div>
				<div
					className={` ${
						checkIsBlocked ? "block" : "hidden"
					} min-w-32 max-w-[65rem] border border-orange-600 px-4 py-3 text-orange-600 my-2`}>
					<p>
						Jedno z twoich API zostało zablokowane ponieważ przekroczyło limit żądań 1mln. Skontaktuj sie z supportem.
					</p>
				</div>
				<div className="min-w-32 max-w-[65rem]">
					<div className="relative overflow-x-auto">
						<table className="w-full text-sm text-left rtl:text-right text-gray-500">
							<thead className="text-xs text-white uppercase  bg-table-custom">
								<tr>
									<th scope="col" className="px-5 py-3">
										Klucz
									</th>
									<th scope="col" className="px-6 py-3">
										Nazwa
									</th>
									<th scope="col" className="px-6 py-3">
										Status
									</th>
									<th scope="col" className="px-6 py-3">
										Akcje
									</th>
									<th scope="col" className="px-6 py-3">
										Serwer
									</th>
									<th scope="col" className="px-6 py-3">
										Liczba zapytań
									</th>
									<th scope="col" className="px-3 py-3">
										Domena
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map(data => (
									<tr className="text-white border-b border-table-custom bg-dashboard-custom even:bg-table-custom">
										<th scope="row" className="px-5 py-4 font-medium  whitespace-nowrap">
											{data.api}
										</th>
										<td className="px-6 py-4">{data.name}</td>
										<td className={`px-6 py-4 ${data.status === "Zablokowany" ? "text-orange-600 font-black" : ""}`}>
											{data.status}
										</td>
										<td className="px-6 py-4 flex items-center gap-1">
											<button onClick={() => deleteApi(data.api)}>
												<span className="material-symbols-outlined text-xl">close</span>
											</button>
											<label className="inline-flex items-center cursor-pointer">
												<input
													type="checkbox"
													className="sr-only peer"
													disabled={data.status === "Zablokowany"}
													onChange={e => statusAPI(data.api, e, data.owner)}
													checked={data.status === "Aktywny"}
												/>
												<div className="relative w-7 h-4 bg-main-color-popup  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 focus:bg-orange-custom after:border after:rounded-full after:h-3 after:w-3 after:transition-all  peer-checked:bg-orange-custom"></div>
											</label>
										</td>
										<td className="px-6 py-4">
											<a href={`/panel/${data.id}`} className="text-orange-custom underline">
												{data.nameServer}
											</a>
										</td>
										<td className="px-6 py-4">{data.reqCount} zapytań</td>
										<td className="px-3 py-4">{data.domain ? data.domain : "Brak"}</td>
									</tr>
								))}
								{data.length === 0 && (
									<tr className="text-white border-b border-table-custom bg-dashboard-custom even:bg-table-custom">
										<th scope="row" className="px-5 py-4 font-medium  whitespace-nowrap">
											Musisz wygenerować klucz API
										</th>
										<td className="px-6 py-4">Brak</td>
										<td className="px-6 py-4">Brak</td>
										<td className="px-6 py-4">Brak</td>
										<td className="px-6 py-4">Brak</td>
										<td className="px-6 py-4">Brak</td>
										<td className="px-3 py-4">Brak</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
				<div className="mt-2">
					<p className="text-lg font-black">Stwórz klucz API</p>
					<form onSubmit={sendData} className="flex flex-col gap-2 items-start mt-2">
						<input
							type="text"
							className={`bg-dashboard-custom py-2  px-2 rounded-lg min-w-32 max-w-56 border ${
								err ? "border-red-500" : "border-dashboard-custom"
							}`}
							value={server === "" ? "" : server}
							placeholder="Nazwa API"
							onChange={e => setServer(e.target.value)}
						/>

						<select
							className={`bg-dashboard-custom py-2 px-2 rounded-lg min-w-32 max-w-56 border ${
								err ? "border-red-500" : "border-dashboard-custom"
							}`}
							value={idServer === "" ? "" : idServer}
							onChange={e => setServerID(e.target.value)}>
							<option value="0">Wybierz serwer przydzielony do API</option>
							{dataServer.map(server => (
								<option value={server.id}>{server.name}</option>
							))}
						</select>
						<button className="bg-orange-custom py-1 px-2 rounded-lg animate-pulse">Utwórz</button>
					</form>
				</div>
				<div className="mt-5 min-w-32 max-w-[60rem]">
					<h2 className="text-2xl font-black">Ograniczenia i informacje</h2>
					<p className="text-lg mt-2">
						Wszystkie <span className="font-black">ważne</span> informacje dotyczące naszego API zawarte są w{" "}
						<a href="/api/docs" className="text-orange-custom underline">
							dokumentacji
						</a>
					</p>
				</div>
				<div className="mt-5">
					<h2 className="text-2xl font-black">Statystki</h2>
					<p className="my-1">Przedstawiony wykres przedstawia dane globalne, wszystkich użytkowników</p>
					<div className="mt-2 min-w-32 max-w-[30rem]">
						<SimpleLineChart />
					</div>
				</div>
				<div className="mt-5">
					<h2 className="text-2xl font-black">Bezpieczenstwo</h2>
					<div className="mt-2 flex items-center gap-2">
						<p>Automatyczne przypisywanie domeny do API</p>
						<label className="inline-flex items-center cursor-pointer">
							<input type="checkbox" className="sr-only peer" onChange={e => offDomain(e)} />
							<div className="relative w-11 h-6  peer-focus:outline-none  rounded-full peer bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-orange-custom"></div>
						</label>
					</div>
				</div>
			</div>
		</>
	)
}

function Support({ isUserLogin, activeCategory }) {
	const [type, setType] = useState("")
	const [err, setErr] = useState("")
	const [listApi, setListApi] = useState([])
	const [description, setDescription] = useState("")
	const [detailOption, setDetailOption] = useState("")
	const [ticketUser, setTicketUser] = useState([])
	const [loaders, setLoaders] = useState(true)
	const [option, setOption] = useState(1)
	const userRef = ref(db, `Accounts/${isUserLogin}/API/`)
	const profileRef = ref(db, `Accounts/${isUserLogin}/ProfileServer/`)
	const id = Date.now()
	const contactRef = ref(db, "Ticket/" + id)
	const ticketRef = ref(db, "Ticket/")

	function reset() {
		onValue(ticketRef, snapshot => {
			if (snapshot.exists()) {
				const dateSnapshot = Object.values(snapshot.val())
				const searchUserTicket = dateSnapshot.filter(owner => owner.creatorTicket === isUserLogin)
				setTicketUser(searchUserTicket)
			} else {
				setTicketUser([])
			}
		})

		return (
			setType(""), setErr(""), setListApi([]), setDescription(""), setDetailOption(""), setOption(1), setLoaders(true)
		)
	}

	useEffect(() => {
		return () => reset()
	}, [activeCategory === "Support"])

	if (activeCategory === "Support") {
		setTimeout(() => {
			if (ticketUser) {
				setLoaders(false)
			}
		}, 300)
	}

	async function checkType() {
		const userAPI = await get(userRef)
		const userProfile = await get(profileRef)

		if (type === "") return setErr("Wybierz typ")
		if (type === "1") {
			if (!userAPI.val()) return setErr("Nie masz utworzonego żadnego klucza")
			setListApi(Object.values(userAPI.val()))
			setOption(2)
		}
		if (type === "2") {
			if (!userProfile.val()) return setErr("Nie masz utworzonego żadnego serwera")
		}
		setErr("")
	}

	function checkDetail() {
		if (detailOption === "") return setErr("Musisz wybrać opcje")
		if (description === "") return setErr("Musisz wprowadzić opis")

		setOption(3)
		setErr("")
	}

	function addContact() {
		if (ticketUser.length >= 2) return toast.error("Nie możesz mieć otwarte więcej niż 2 tickety.")

		fetch(process.env.REACT_APP_CREATE_TICKET, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ id: id, username: isUserLogin }),
		})
			.then(res => res.json())
			.then(data => {
				if (data.code === 200) {
					const data = {
						id: id,
						api: detailOption,
						description: description,
						dateCreate: event.toLocaleDateString("pl-PL", options),
						creatorTicket: isUserLogin,
						status: "Otwarty",
					}

					set(contactRef, data)
					reset()
					toast.success("Email został pomyślnie wysłany.")
				}
			})
	}

	function updateStatus(id) {
		const ticketRef = ref(db, `Ticket/${id}`)

		update(ticketRef, { status: "Zamknięty" })
	}

	return (
		<>
			<LoaderAnimation loaders={loaders} />
			<div className={`${loaders ? "hidden" : "block"} show mt-10 mx-10 max-md:mx-2 min-w-32 max-w-[40rem]`}>
				<h2 className="font-black text-3xl">Kontakt z administracją</h2>
				<div className="min-w-32 max-w-[35rem]  bg-dashboard-custom mt-3 py-5 px-5 rounded-lg">
					<ol className="flex items-center w-full text-sm font-medium text-center text-gray-400  sm:text-base">
						<li className="flex w-full items-center text-orange-custom  sm:after:content-[''] after:w-full after:h-1 after:border-b  after:border-1 after:hidden sm:after:inline-block after:mx-6  after:border-gray-700">
							<p className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500">
								<span className="material-symbols-outlined text-xl mr-0.5">check_circle</span>
								<button className="flex" onClick={() => setOption(1)}>
									Wybieranie
								</button>
							</p>
						</li>
						<li
							className={`${
								option >= 2 ? "text-orange-custom" : ""
							} flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b  after:border-1 after:hidden sm:after:inline-block after:mx-6  after:border-gray-700`}>
							<p className="flex items-center after:content-['/'] sm:after:hidden after:mx-2  after:text-gray-500">
								<span className={`${option >= 2 ? "material-symbols-outlined text-xl" : ""} me-2 mr-0.5`}>
									{option >= 2 ? "check_circle" : "2."}
								</span>
								{option === 3 ? <button onClick={() => setOption(2)}>Szczegóły</button> : <p>Szczegóły</p>}
							</p>
						</li>
						<li className={`${option > 2 ? "text-orange-custom" : ""} flex items-center`}>
							<p className="flex items-center after:content-['/'] sm:after:hidden after:mx-2  after:text-gray-500">
								<span className={`${option >= 2 ? "material-symbols-outlined text-xl" : ""} me-2  mr-0.5`}>
									{option >= 2 ? "check_circle" : "3."}
								</span>
								Potwierdź
							</p>
						</li>
					</ol>
					<div className={`${option === 1 ? "flex" : "hidden"} flex-col mt-5`}>
						<label htmlFor="type" className="text-lg">
							Wybierz typ
						</label>
						<select
							id="type"
							className="py-2 px-2 border-none bg-color-input-support rounded-lg my-1"
							onChange={e => setType(e.target.value)}
							value={type}>
							<option value="0">Kliknij aby wybrać</option>
							<option value="1">Klucz API</option>
							<option value="2">Profile serwerów</option>
						</select>
						<p className={`${err !== "" ? "block" : "hidden"} text-red-600`}>[ERROR] {err}</p>
						<button className="bg-orange-custom py-1 px-2 w-fit my-1 rounded-lg" onClick={checkType}>
							Przejdź dalej
						</button>
					</div>
					<div className={`${option === 2 ? "flex" : "hidden"} flex-col mt-5`}>
						<label htmlFor="api" className="text-lg">
							Wybierz swój klucz API
						</label>
						<select
							id="api"
							className="py-2 px-2 border-none bg-color-input-support rounded-lg my-1"
							onChange={e => setDetailOption(e.target.value)}>
							<option value="0">Kliknij aby wybrać</option>
							{listApi.map(key => (
								<option value={key.api}>{key.api}</option>
							))}
						</select>
						<label htmlFor="description" className="mt-2 text-lg">
							Opisz co chcesz zrobić
						</label>
						<textarea
							onChange={e => setDescription(e.target.value)}
							id="description"
							value={description === "" ? "" : description}
							cols="30"
							rows="5"
							className="bg-color-input-support resize-none rounded-lg py-2 px-2"></textarea>
						<p className={`${err !== "" ? "block" : "hidden"} text-red-600`}>[ERROR] {err}</p>
						<div className="flex gap-2">
							<button className="bg-orange-custom py-1 px-2 w-fit my-1 rounded-lg" onClick={checkDetail}>
								Przejdź dalej
							</button>
						</div>
					</div>
					<div className={`${option === 3 ? "flex" : "hidden"} flex-col mt-5`}>
						<h2 className="text-xl font-black">Potwierdzanie</h2>
						<ul className="my-2 ">
							<li className="my-1">Klucz API: {detailOption}</li>
							<li className="my-1">Opis: {description}</li>
						</ul>
						<p className="mt-2">
							Czy aby napewno chcesz stworzyć ticket numer <span className="font-black">{id}</span>
						</p>
						<button className="bg-orange-custom py-1 px-2 w-fit mt-3 rounded-lg" onClick={addContact}>
							Stwórz ticket
						</button>
					</div>
				</div>
				<div className="mt-10 min-w-32 max-w-[65rem]">
					<h2 className="font-black text-2xl">Twoje tickety</h2>
					<p className={`${ticketUser.length === 0 ? "block" : "hidden"}`}>Nie masz żadnych aktywnych ticketów</p>
					<div className="relative overflow-x-auto">
						<table
							className={`w-full ${
								ticketUser.length === 0 ? "hidden" : "block"
							} text-sm text-left rtl:text-right text-gray-500 mt-3`}>
							<thead className="text-xs text-white uppercase  bg-table-custom">
								<tr>
									<th scope="col" className="px-5 py-3">
										ID
									</th>
									<th scope="col" className="px-6 py-3">
										Status
									</th>
									<th scope="col" className="px-6 py-3">
										Opis
									</th>
									<th scope="col" className="px-6 py-3">
										Stworzony
									</th>
									<th scope="col" className="px-6 py-3">
										Typ
									</th>
									<th scope="col" className="px-6 py-3">
										Akcje
									</th>
								</tr>
							</thead>
							<tbody>
								{ticketUser.map(ticket => (
									<tr className="text-white border-b border-table-custom bg-dashboard-custom even:bg-table-custom">
										<th scope="row" className="px-5 py-4 font-medium  whitespace-nowrap">
											{ticket.id}
										</th>
										<td
											className={`px-6 py-4 ${
												ticket.status === "Otwarty" ? "text-orange-custom" : "text-red-600"
											} font-black`}>
											{ticket.status}
										</td>
										<td className="px-6 py-4">
											<ReactToolTip id="my-tooltip" />
											<a data-tooltip-id="my-tooltip" data-tooltip-content={ticket.description}>
												Najedź
											</a>
										</td>
										<td className="px-6 py-4">
											<button>{ticket.dateCreate}</button>
										</td>
										<td className="px-6 py-4">
											<button>API</button>
										</td>
										<td className="px-6 py-4">
											<button
												className="underline text-blue-500"
												disabled={ticket.status === "Zamknięty"}
												onClick={() => updateStatus(ticket.id)}>
												Zamknij
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

function Stats({ loader, activeCategory, sidebar, isUserLogin }) {
	const [loaders, setLoaders] = useState(true)
	const [userIsNotAuthDiscord, setIsNotAuth] = useState(false)
	const [data, setData] = useState([])
	const [chartDataPie, setChartData] = useState([])

	const loadDataStats = async () => {
		const templateRef = ref(db, `Template/box`)
		const templateUser = ref(db, `Accounts/${isUserLogin}/oauth2/`)
		const apiUser = ref(db, `Accounts/${isUserLogin}/API`)
		const historyReportRef = ref(db, `Accounts/${isUserLogin}/historyReport`)
		const apiAllRef = ref(db, `API/`)

		try {
			const userSnapshot = await get(templateUser)
			if (!userSnapshot.exists()) return setIsNotAuth(true), setLoaders(false)

			const templateSnapshot = await get(templateRef)
			const apiSnapshot = await get(apiUser)
			const userSnapshotTemplate = ref(db, `User/${userSnapshot.val().accountDiscordId}`)
			const userTemplates = await get(userSnapshotTemplate)
			const allReqCountApi = await get(apiAllRef)
			const historyReport = await get(historyReportRef)

			const apiData = apiSnapshot.exists() ? Object.values(apiSnapshot.val()) : []
			const historyData = historyReport.exists() ? Object.values(historyReport.val()) : []
			const apiAllReqCount = Object.values(allReqCountApi.val())

			const reqCountAll = apiAllReqCount.reduce((acc, currentAcc) => {
				return { reqCount: acc.reqCount + currentAcc.reqCount }
			})

			const reqCount = apiData.reduce(
				(acc, currentAcc) => {
					return { reqCount: acc.reqCount + currentAcc.reqCount }
				},
				{ reqCount: 0 }
			)

			const dataToSend = {
				templateCount: Object.values(templateSnapshot.val()).length,
				userData: userTemplates.val(),
				reqCountApi: reqCount.reqCount,
				allReqCountApi: reqCountAll.reqCount,
				templateData: [],
				historyReport: historyData,
			}

			if (userTemplates.val().templateCount > 0) {
				for (const item of Object.values(userTemplates.val().templates)) {
					const templateUserRef = ref(db, `Template/box/${item}`)

					const templateUserData = await get(templateUserRef)

					dataToSend.templateData.push(templateUserData.val())
				}
			}

			setData(dataToSend)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		if (activeCategory === "Stats") {
			loadDataStats()
		}

		return () => setData([]), setLoaders(true)
	}, [activeCategory])

	useEffect(() => {
		if (data.templateCount > 0) {
			setLoaders(false)
		}
	}, [data])

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<>
					<div className="bg-white text-black p-2 rounded-lg">
						<p>Liczba: {payload[0].payload.count}</p>
						<p>Data: {payload[0].payload.date}</p>
					</div>
				</>
			)
		}
	}

	const countByDate =
		data.templateData &&
		data.templateData.reduce((acc, template) => {
			const date = template.dateCreate
			if (!acc[date]) {
				acc[date] = { date: date, count: 0 }
			}
			acc[date].count += 1

			return acc
		}, {})

	const testFile = [
		{ date: "11.05.2024", count: 10 },
		{ date: "12.05.2024", count: 20 },
		{ date: "13.05.2024", count: 30 },
		{ date: "14.05.2024", count: 40 },
		{ date: "15.05.2024", count: 50 },
		{ date: "16.05.2024", count: 60 },
		{ date: "17.05.2024", count: 70 },
		{ date: "18.05.2024", count: 80 },
		{ date: "19.05.2024", count: 90 },
	]

	const dataChart =
		data.templateData && data.templateData.length !== 0 ? Object.values(countByDate) : Object.values(testFile)

	const dataPieChart = [
		{ name: "Twoje szablony", value: data.userData && data.userData.templateCount },
		{ name: "Szablony użytkowników", value: data.templateCount && data.templateCount },
	]

	const dataPieChartApiReqCount = [
		{ name: "Twoje zapytania", value: data.reqCountApi && data.reqCountApi },
		{ name: "Wszystkie zapytania", value: data.allReqCountApi && data.allReqCountApi },
	]

	const COLORS = ["#11998e", "#3B82F6"]

	const RADIAN = Math.PI / 180
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5
		const x = cx + radius * Math.cos(-midAngle * RADIAN)
		const y = cy + radius * Math.sin(-midAngle * RADIAN)

		return (
			<text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		)
	}

	return (
		<>
			<div
				className={`${
					userIsNotAuthDiscord ? "block" : "hidden"
				} absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center`}>
				<h2 className="text-5xl">Nie masz do tego dostępu</h2>
				<p className="py-2">Abyśmy mogli załadować prawidlowo dane musisz mieć zautoryzowane konto discord</p>
				<a href={process.env.REACT_APP_DISCORD_REDIRECT} className="uppercase underline text-2xl">
					Zautoryzuj konto discord
				</a>
			</div>
			<LoaderAnimation loaders={loaders} />
			<div
				className={`${
					userIsNotAuthDiscord || loaders ? "hidden" : "block"
				} show flex-col grow-0 px-10 max-lg:px-5 mt-5 max-md:px-2 pb-5`}>
				<div className="dashboard-stats">
					<div className="flex items-center gap-2">
						<span className="material-symbols-outlined bg-orange-custom py-1 px-1 rounded-lg">bar_chart_4_bars</span>
						<h2 className="text-xl font-semibold">Statystyki uzytkownika</h2>
					</div>
					<div className="stats mt-5 flex gap-5 flex-wrap">
						<div className="stat py-5 px-5 w-[23rem] bg-dashboard-custom rounded-lg flex items-center gap-5">
							<span className="material-symbols-outlined text-[4rem] bg-blue-500 px-2 py-1 rounded-lg ">category</span>
							<div className="">
								<h3 className="my-1 text-lg">Wszystkie szablony</h3>
								<p className="text-3xl font-medium">
									<CountUp start={loaders} end={data.templateCount} duration={5} />
								</p>
							</div>
						</div>
						<div className="stat py-5 px-5 w-[23rem] bg-dashboard-custom rounded-lg flex items-center gap-5">
							<span className="material-symbols-outlined text-[4rem] bg-orange-custom px-2 py-1 rounded-lg ">
								category
							</span>
							<div className="">
								<h3 className="my-1 text-lg">Twoje szablony</h3>
								<p className="text-3xl font-medium">
									<CountUp start={loaders} end={data.userData && data.userData.templateCount} duration={5} />
								</p>
							</div>
						</div>
						<div className="stat py-5 px-5 w-[23rem] bg-dashboard-custom rounded-lg flex items-center gap-5">
							<span className="material-symbols-outlined text-[4rem] bg-blue-500 px-2 py-1 rounded-lg ">code</span>
							<div className="">
								<h3 className="my-1 text-lg">Zapytan do API</h3>
								<p className="text-3xl font-medium">
									{data.reqCountApi === undefined ? (
										"0"
									) : (
										<CountUp start={loaders} end={data.reqCountApi} duration={5} />
									)}
								</p>
							</div>
						</div>
					</div>
					<div className="mt-5 w-full flex gap-5 max-xl:flex-col">
						<div className="bg-dashboard-custom w-1/2 h-96 p-4 rounded-lg max-xl:w-full">
							<p>Twoje wysłane szablony z ostatnich 30 dni.</p>
							<div className="py-2 relative w-full">
								<div
									className={`${
										data.templateData && data.templateData.length === 0 ? "block" : "hidden"
									} absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[150] text-center`}>
									<p className="text-2xl font-black">Nie masz do tego dostępu</p>
									<p>Aby uzyskać dostęp do statystyk szablonowych dodaj chociaż jeden szablon</p>
								</div>
								<div
									className={`${
										data.templateData && data.templateData.length === 0 ? "blur-md pointer-events-none" : ""
									}`}>
									<ResponsiveContainer height="95%" width="100%">
										<BarChart data={dataChart} maxBarSize={50}>
											<XAxis dataKey="date" />
											<CartesianGrid strokeDasharray="3 3" />

											<Tooltip content={<CustomTooltip />} />
											<YAxis orientation="right" />
											<Bar dataKey="count" fill="#11998e" />
										</BarChart>
									</ResponsiveContainer>
								</div>
							</div>
						</div>
						<div className="w-1/2 flex gap-5 max-xl:w-full max-sm:flex-col">
							<div className="bg-dashboard-custom h-96 p-4 rounded-lg flex items-center w-full">
								<ResponsiveContainer width="100%" height="100%">
									<PieChart width={400} height={400}>
										<Pie
											data={dataPieChart}
											cx="50%"
											cy="50%"
											labelLine={false}
											label={renderCustomizedLabel}
											fill="#8884d8"
											dataKey="value">
											{dataPieChart.map((entry, index) => (
												<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
											))}
										</Pie>
										<Tooltip />
										<Legend verticalAlign="top" height={36} />
									</PieChart>
								</ResponsiveContainer>
							</div>
							<div className="bg-dashboard-custom h-96 p-4 rounded-lg flex items-center w-full">
								<ResponsiveContainer width="100%" height="100%">
									<PieChart width={400} height={400}>
										<Pie
											data={dataPieChartApiReqCount}
											cx="50%"
											cy="50%"
											labelLine={false}
											label={renderCustomizedLabel}
											fill="#8884d8"
											dataKey="value">
											{dataPieChartApiReqCount.map((entry, index) => (
												<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
											))}
										</Pie>
										<Tooltip />
										<Legend verticalAlign="top" height={36} />
									</PieChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-5 w-full flex gap-5 max-xl:flex-col">
					<div className="overflow-x-auto shadow-md sm:rounded-lg w-1/2 max-lg:w-full max-xl:w-full">
						<table className="w-full text-sm text-left rtl:text-right ">
							<caption className="p-5 text-lg font-semibold text-left rtl:text-right  text-white bg-dashboard-custom">
								Twoje zgłoszenia szablonów ({data.historyReport && data.historyReport.length})
							</caption>
							<thead className="text-xs  uppercase  bg-table-custom text-gray-400">
								<tr>
									<th scope="col" className="px-6 py-3">
										ID
									</th>
									<th scope="col" className="px-6 py-3">
										ID Twórcy
									</th>
									<th scope="col" className="px-6 py-3">
										Twórca
									</th>
									<th scope="col" className="px-6 py-3">
										Powód
									</th>
								</tr>
							</thead>
							<tbody className="w-full">
								{data.historyReport &&
									data.historyReport.map((report, index) => (
										<tr className="border-b border-table-custom bg-dashboard-custom even:bg-table-custom" key={index}>
											<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
												<a href={`${`/templates/${report.id}`}`} className="text-orange-custom underline">
													{report.id}
												</a>
											</th>
											<td className="px-6 py-4">{report.creatorID == null ? "Brak" : report.creatorID}</td>
											<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
												{report.creator == null ? "Brak" : report.creator}
											</th>
											<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
												{report.reason}
											</th>
										</tr>
									))}
								{data.historyReport && (
									<tr
										className={`${
											data.historyReport.length === 0 ? "visible" : "hidden"
										} border-b border-table-custom bg-dashboard-custom even:bg-table-custom w-full`}>
										<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
											Brak
										</th>
										<td className="px-6 py-4">Brak</td>
										<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
											Brak
										</th>
										<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
											Brak
										</th>
									</tr>
								)}
							</tbody>
						</table>
					</div>
					<div className="overflow-x-auto shadow-md sm:rounded-lg w-1/2 max-lg:w-full max-xl:w-full">
						<table className="w-full text-sm text-left rtl:text-right ">
							<caption className="p-5 text-lg font-semibold text-left rtl:text-right  text-white bg-dashboard-custom">
								Twoje zgłoszenia szablonów ({data.historyReport && data.historyReport.length})
							</caption>
							<thead className="text-xs  uppercase  bg-table-custom text-gray-400">
								<tr>
									<th scope="col" className="px-6 py-3">
										ID
									</th>
									<th scope="col" className="px-6 py-3">
										ID Twórcy
									</th>
									<th scope="col" className="px-6 py-3">
										Twórca
									</th>
									<th scope="col" className="px-6 py-3">
										Powód
									</th>
								</tr>
							</thead>
							<tbody className="w-full">
								{data.historyReport &&
									data.historyReport.map((report, index) => (
										<tr className="border-b border-table-custom bg-dashboard-custom even:bg-table-custom" key={index}>
											<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
												<a href={`${`/templates/${report.id}`}`} className="text-orange-custom underline">
													{report.id}
												</a>
											</th>
											<td className="px-6 py-4">{report.creatorID == null ? "Brak" : report.creatorID}</td>
											<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
												{report.creator == null ? "Brak" : report.creator}
											</th>
											<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
												{report.reason}
											</th>
										</tr>
									))}
								{data.historyReport && (
									<tr
										className={`${
											data.historyReport.length === 0 ? "visible" : "hidden"
										} border-b border-table-custom bg-dashboard-custom even:bg-table-custom w-full`}>
										<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
											Brak
										</th>
										<td className="px-6 py-4">Brak</td>
										<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
											Brak
										</th>
										<th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap text-white">
											Brak
										</th>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export function Panel() {
	const [activeCategory, setActiveCategory] = useState(null)
	const [sidebar, setSidebar] = useState(true)
	const [permission, setPermission] = useState(true)
	const [isUserLogin, setUserLogin] = useState("")
	const [coins, setCoins] = useState("")
	const [loader, setLoader] = useState(true)
	const accountRef = ref(db, "Accounts/" + isUserLogin)
	const token = localStorage.getItem("token")
	const { dataToken } = useCheckToken(token)
	const navigate = useNavigate()

	useEffect(() => {
		if (token && dataToken) {
			if (dataToken.code === 200) return setUserLogin(dataToken.message.username)
			if (dataToken.code === 401) return navigate("/")
		}
	}, [dataToken, token])

	get(accountRef).then(snapshot => {
		setCoins(snapshot.val().coins === 0 ? "" : snapshot.val().coins)
		if (snapshot.val().permission === "a") return setPermission(false)

		if (snapshot.val() && dataToken && token) {
			setTimeout(() => {
				setLoader(false)
			}, 150)
		}
	})

	useEffect(() => {
		if (!permission) {
			setActiveCategory("Stats")
		} else {
			setActiveCategory("Dashboard")
		}
	}, [permission])

	useEffect(() => {
		function resizeHandle() {
			if (window.innerWidth <= 768) {
				setSidebar(false)
			} else {
				setSidebar(true)
			}
		}

		window.addEventListener("resize", resizeHandle)

		return () => window.removeEventListener("resize", resizeHandle)
	}, [])

	return (
		<>
			<TopNavPanel sidebar={sidebar} setSidebar={setSidebar} isUserLogin={isUserLogin} coins={coins} />
			<Toaster position="top-center" reverseOrder={false} />
			<Loader loader={loader} />

			<main className="flex h-[95%] bg-[#24252b] z-50">
				<SidebarPanel
					sidebar={sidebar}
					setSidebar={setSidebar}
					activeCategory={activeCategory}
					setActiveCategory={setActiveCategory}
					permission={permission}
					coins={coins}
				/>
				<div className="max-w-container mx-auto w-full overflow-auto relative">
					<section className={` w-full ${permission !== false && activeCategory === "Dashboard" ? "block" : "hidden"}`}>
						<Dashboard loader={loader} activeCategory={activeCategory} sidebar={sidebar} />
					</section>
					<section className={` w-full ${activeCategory === "Stats" ? "block" : "hidden"}`}>
						<Stats loader={loader} activeCategory={activeCategory} sidebar={sidebar} isUserLogin={isUserLogin} />
					</section>
					<section
						className={` w-full ${permission !== false && activeCategory === "MenageAccounts" ? "block" : "hidden"}`}>
						<MenageUsers activeCategory={activeCategory} />
					</section>
					<section
						className={` w-full ${permission !== false && activeCategory === "MenageTemplates" ? "block" : "hidden"}`}>
						<MenageTemplates activeCategory={activeCategory} />
					</section>
					<section className={` w-full  ${activeCategory === "ProfileServer" ? "block" : "hidden"}`}>
						<ProfileServer isUserLogin={isUserLogin} activeCategory={activeCategory} />
					</section>
					<section className={` w-full ${activeCategory === "API" ? "block" : "hidden"}`}>
						<Api isUserLogin={isUserLogin} activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
					</section>
					<section className={` w-full ${activeCategory === "Support" ? "block" : "hidden"}`}>
						<Support isUserLogin={isUserLogin} activeCategory={activeCategory} />
					</section>
				</div>
			</main>
		</>
	)
}
