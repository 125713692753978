import React from "react"
import ReactDOM from "react-dom/client"
import "react-tooltip/dist/react-tooltip.css"
import { App } from "./components/App"
import { Routes, Route, BrowserRouter } from "react-router-dom"
import { Login } from "./components/panel/login"
import { Panel } from "./components/panel/Dashboard"
import { PageNotFound } from "./components/NotFound"
import { TemplateCreate } from "./components/templates/TemplateCreate"
import { TemplateList } from "./components/templates/TemplateList"
import { TemplateDetails } from "./components/templates/TemplateDetails"
import { UserProfile } from "./components/templates/UserProfiles"
import { Documentation } from "./components/Apidocs"
import { PrivateRouter } from "./components/auth/PrivateRouter"
import { ProfileServers } from "./components/panel/profileServer/Profile"
import { Register } from "./components/panel/Register"
import { ForgotPassword } from "./components/panel/ForgotPassword"
import { RequestResetPassword } from "./components/panel/RequestReset"
import { CategoryTemplate } from "./components/templates/CategoriesTemplate"
import { Loading } from "./components/App"
import Auth from "./components/panel/Auth"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<BrowserRouter>
		<Loading />
		<Routes>
			<Route path="/" element={<App />} />
			<Route path="*" element={<PageNotFound />} />
			<Route path="/login" element={<Login />} />
			<Route path="/register" element={<Register />} />
			<Route path="/forgotPassword" element={<ForgotPassword />} />
			<Route path="/requestResetPassword" element={<RequestResetPassword />} />
			<Route path="/auth" element={<Auth />} />
			<Route path="/category/:categoriesName" element={<CategoryTemplate />} />
			<Route
				path="/panel"
				element={
					<PrivateRouter>
						<Panel />
					</PrivateRouter>
				}
			/>
			<Route path="/create" element={<TemplateCreate />} />
			<Route path="/templates" element={<TemplateList />} />
			<Route path="/api/docs" element={<Documentation />} />
			<Route path="/templates/:templateId" element={<TemplateDetails />} />
			<Route
				path="/panel/:idServer"
				element={
					<PrivateRouter>
						<ProfileServers />
					</PrivateRouter>
				}
			/>
			<Route path="/user/:userId" element={<UserProfile />} />
		</Routes>
	</BrowserRouter>
)
