import { Navbar, Footer } from "../App"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import passwordFogotImg from "../../img/secure-folder.png"
import toast, { Toaster } from "react-hot-toast"
import { getDatabase, ref, get } from "firebase/database"
import ErrorModal from "../hook/ModalErr"
const db = getDatabase()
/* global grecaptcha */

export function Register() {
	const [password, passwordChange] = useState("")
	const [repeatPassoword, setRepeatPassowrd] = useState("")
	const [email, setEmail] = useState("")
	const [username, loginChange] = useState("")
	const [paragraphErrText, setparagraphErrText] = useState("")
	const [errModal, setErrModal] = useState(false)

	async function sendForm() {
		const regex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/
		const regexUser = /[\.\$\#\[\]]/
		const userInBase = ref(db, "Accounts/")

		if (regexUser.test(username.toLowerCase())) {
			setErrModal(true)
			setparagraphErrText("Nazwa nie może zawierać . # $ [ ]")
			return
		}

		const userCheck = ref(db, "Accounts/" + username)

		if (password === "" || username === "" || repeatPassoword === "" || email === "") {
			setErrModal(true)
			setparagraphErrText("Uzupełnij wszystkie pola")
			return
		}
		const snapshot = await get(userCheck)
		if (snapshot.exists()) {
			setErrModal(true)
			setparagraphErrText("Nazwa użytkownika jest już zajęta")
			return
		}

		const snapshotUsers = await get(userInBase)
		const informationUsersEmail = Object.values(snapshotUsers.val()).some(key => key.email === email)

		if (informationUsersEmail) {
			setErrModal(true)
			setparagraphErrText("Ten email już jest zajęty")
			return
		}

		if (password !== repeatPassoword) {
			setErrModal(true)
			setparagraphErrText("Hasła nie są takie same")
			return
		}

		if (password.length <= 5) {
			setErrModal(true)
			setparagraphErrText("Hasło za krótkie, minimum 6 znaków")
			return
		}

		if (!regex.test(email)) {
			setErrModal(true)
			setparagraphErrText("Email jest nieprawidłowy")
			return
		}

		const token = await grecaptcha.enterprise.execute("6Le9O38pAAAAAER3-gszQ1xCZMIOH6HS2ip4ey4-", {
			action: "submit",
		})

		const register = await fetch(process.env.REACT_APP_REGISTER, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
			},
			body: JSON.stringify({ password, repeatPassoword, email, username, token }),
		})

		const registerData = await register.json()

		if (registerData.code === 403)
			return toast.error("Nie możesz się zarejestrować bo najprawdopodobniej jesteś botem..")

		if (registerData.code === 200) {
			toast.success("Rejestracja przebiegła pomyslnie")
			setTimeout(() => {
				window.location.href = "/login"
			}, 500)
		}
	}

	useEffect(() => {
		if (errModal !== false) {
			setTimeout(() => {
				setErrModal(false)
			}, 2500)
		}
	}, [errModal])

	return (
		<>
			<Navbar />
			<Toaster position="top-center" reverseOrder={false} />
			<main>
				<ErrorModal errModal={errModal} paragraphErrText={paragraphErrText} />
				<section className="flex flex-col items-center absolute top-1/2 translate-y-[-50%]  w-full px-4">
					<div className="flex max-sm:w-full max-sm:flex-col items-center bg-main-color-popup rounded-lg py-5 px-5 gap-10 shadow-xl">
						<img src={passwordFogotImg} alt="image register" className="w-72 h-64 max-sm:hidden" />
						<div className="w-80 max-sm:w-11/12">
							<h2 className="text-3xl font-semibold">Rejestracja</h2>
							<div className="mt-3">
								<div className="relative z-0 max-sm:mt-4">
									<div className="">
										<input
											type="text"
											id="username"
											name="username"
											className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2  appearance-none  border-gray-600  focus:border-orange-custom focus:outline-none focus:ring-0  peer"
											placeholder=" "
											onChange={e => loginChange(e.target.value)}
										/>
										<label
											htmlFor="username"
											className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-custom peer-focus:dark:text-orange-custom peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
											Wpisz swój nick
										</label>
									</div>
								</div>
								<div className="relative z-0 mt-3 max-sm:my-4">
									<input
										type="text"
										id="email"
										name="email"
										className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2  appearance-none  border-gray-600  focus:border-orange-custom focus:outline-none focus:ring-0  peer"
										placeholder=" "
										onChange={e => setEmail(e.target.value)}
									/>
									<label
										htmlFor="email"
										className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-custom peer-focus:dark:text-orange-custom peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
										Wpisz swój email
									</label>
								</div>
								<div className="mt-3 w-full flex gap-2 max-sm:flex-col">
									<div className="relative w-1/2 max-sm:w-full z-0 max-sm:my-1">
										<input
											type="password"
											id="password"
											name="password"
											className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2  appearance-none  border-gray-600  focus:border-orange-custom focus:outline-none focus:ring-0  peer"
											placeholder=" "
											onChange={e => passwordChange(e.target.value)}
										/>
										<label
											htmlFor="password"
											className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-custom peer-focus:dark:text-orange-custom peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
											Hasło
										</label>
									</div>
									<div className="relative w-1/2 max-sm:w-full z-0 max-sm:my-1">
										<input
											type="password"
											id="RepeatPassoword"
											name="RepeatPassoword"
											className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2  appearance-none  border-gray-600  focus:border-orange-custom focus:outline-none focus:ring-0  peer"
											placeholder=" "
											onChange={e => setRepeatPassowrd(e.target.value)}
										/>
										<label
											htmlFor="RepeatPassoword"
											className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-custom peer-focus:dark:text-orange-custom peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
											Powtórz hasło
										</label>
									</div>
								</div>
								<div className="flex items-center mt-3">
									<p>
										Masz już konto?{" "}
										<Link to="/login" className="underline text-orange-custom">
											Zaloguj się
										</Link>
									</p>
								</div>
								<button className="mt-3 bg-orange-custom py-2 px-4 rounded-lg" onClick={sendForm}>
									Zarejestruj się
								</button>
							</div>
						</div>
					</div>
				</section>
			</main>
			<div className="absolute bottom-0 flex justify-center w-full">
				<Footer />
			</div>
		</>
	)
}
