import { useNavigate, useParams } from "react-router-dom"
import { Navbar, Footer } from "../App"
import { getDatabase, onValue, ref, set, update, get, remove } from "firebase/database"
import { useEffect, useState } from "react"
import userLogoDiscord from "../../img/user-logo.webp"
import Loader from "../hook/Loader"
import { Tooltip } from "react-tooltip"
import CountUp from "react-countup"
import templateNotFound from "../../img/templateIs-not-found.png"
import toast, { Toaster } from "react-hot-toast"
import useCheckToken from "../hook/checkTokenHook"
import { UserNotLogin } from "../hook/UserIsNotLogin.jsx"
const db = getDatabase()
const token = localStorage.getItem("token")
/* global grecaptcha */

function ReportTemplate({ templateId, report, setReport, dataToken, templateInfo }) {
	const [reasonReport, setReasonReport] = useState("")
	const [reportUser, setUserReport] = useState([])
	const [errReport, setErrReport] = useState("")

	useEffect(() => {
		if (report === true) {
			document.documentElement.style.overflow = "hidden"
		} else {
			document.documentElement.style.overflow = "auto"
		}
	}, [report])

	function reportTemplate(event) {
		if (reasonReport.length === 0 || reasonReport.length >= 500) return
		event.preventDefault()
		const isClick = reportUser.target && reportUser.target.checked
		const username = dataToken.message.username

		grecaptcha.enterprise.ready(async () => {
			const token = await grecaptcha.enterprise.execute("6Le9O38pAAAAAER3-gszQ1xCZMIOH6HS2ip4ey4-", {
				action: "submit",
			})

			fetch(process.env.REACT_APP_REPORT_USER, {
				method: "POST",
				headers: {
					"Content-type": "application/json",
				},
				body: JSON.stringify({ token, reasonReport, templateId, templateInfo, isClick, username }),
			})
				.then(res => res.json())
				.then(data => {
					if (data.errorType === "reportExists") return setErrReport("Zgłoszenie na ten szablon już istnieje")
					if (data.code === 429) return setErrReport("System wykrył, że jesteś botem..")
					if (data.code === 500) return setErrReport("Nastąpił błąd serwera, spróbuj później")
					if (data.code === 200) return setErrReport(""), setReport(false), toast.success("Zgłoszenie zostało wysłane")
				})
		})
	}

	useEffect(() => {
		if (!report) {
			setReasonReport("")
			setErrReport("")
		}
	}, [report])

	return (
		<>
			{report && <div className="fixed top-0 left-0 w-full h-full bg-black-transparent overflow-y-hidden z-50"></div>}
			<div
				className={`${
					report ? "block" : "hidden"
				} show bg-popup-custom w-[30rem] max-md:w-11/12 mr-5 z-[150] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl`}>
				<div className="p-6">
					<h2 className="text-xl font-medium">Zgłoś szablon użytkownika</h2>
				</div>
				<UserNotLogin isUserLogin={dataToken.code === 401 ? "" : "dziala"} />
				<div className="border border-b border-input-border-color"></div>
				<div className="p-6">
					<div className="">
						<p className="font-semibold">Powód zgloszenia szablonu</p>
						<p className="py-0.5">Dlaczego chcesz zgłosić szablon tego użytkownika?</p>
						<textarea
							className={`w-full bg-popup-custom border ${
								reasonReport.length >= 500 ? "border-red-500 shadow-sm shadow-red-500" : "border-input-border-color"
							} mt-2 h-36 resize-none p-2`}
							onChange={e => setReasonReport(e.target.value)}
							value={reasonReport}></textarea>
						<p className={`${reasonReport.length >= 500 ? "text-red-500" : "text-gray-400"} mt-1 text-sm `}>
							{reasonReport.length}/500
						</p>
					</div>
					<p className={`${errReport !== "" ? "visible" : "invisible"} text-red-500 py-0.2`}>[BŁĄD] {errReport}</p>
					<div className="flex w-full justify-between max-md:flex-col">
						<div className="flex items-center gap-1 max-md:justify-between">
							<p>Zglosić teź użytkownika?</p>
							<label className="inline-flex items-center cursor-pointer" onChange={e => setUserReport(e)}>
								<input type="checkbox" className="sr-only peer" />
								<div className="relative max-md:w-11 max-md:h-6 w-7 h-4  peer-focus:outline-none  rounded-full peer bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full max-md:after:h-5 max-md:after:w-5 after:h-3 after:w-3 after:transition-all border-gray-600 peer-checked:bg-orange-custom"></div>
							</label>
						</div>
						<div className="flex gap-2 max-md:flex-col max-md:mt-2">
							<button
								className="border border-input-border-color py-1.5 px-3 rounded-full hover:bg-input-border-color"
								onClick={() => setReport(false)}>
								Anuluj
							</button>
							<button className="bg-orange-custom py-1.5 px-3 rounded-full" onClick={reportTemplate}>
								Zgłoś
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

function DeleteTemplate({
	deleteTemplates,
	templateId,
	setDeleteTemplates,
	userData,
	navigate,
	templateInfo,
	templateRef,
}) {
	const deleteTemplate = async () => {
		if (userData.permission === "a") return

		const deleteTemplateUserRef = ref(db, `User/${templateInfo.creator.id}`)

		try {
			const deleteTemplateSnapshot = await get(deleteTemplateUserRef)
			const templatesArray = deleteTemplateSnapshot.val().templates

			templatesArray.pop(templateId)
			update(deleteTemplateUserRef, {
				templateCount: deleteTemplateSnapshot.val().templateCount - 1,
				templates: templatesArray,
			})
			remove(templateRef)
			setDeleteTemplates(false)
			navigate("/templates")
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<>
			{deleteTemplates && (
				<div className="fixed top-0 left-0 w-full h-full bg-black-transparent overflow-y-hidden z-50"></div>
			)}
			<div
				className={`${
					deleteTemplates ? "block" : "hidden"
				} show fixed  left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-96 max-md:w-11/12 bg-popup-custom px-5 py-5 rounded-lg text-center z-[150]`}>
				<div className="flex justify-center w-full -translate-y-14">
					<span className="material-symbols-outlined text-5xl bg-orange-custom px-3 py-3 rounded-full">
						question_mark
					</span>
				</div>
				<div className="-translate-y-8">
					<h2 className="text-2xl">Ostrzeżenie!</h2>
					<p className="mt-2">
						Czy aby napewno chcesz usunąć serwer <span className="font-black">{templateId}</span> z bazy danych?
					</p>
				</div>
				<div className="flex gap-1 w-full">
					<button className=" bg-orange-custom w-1/2 py-2" onClick={deleteTemplate}>
						Potwierdź
					</button>
					<button className="bg-red-500 w-1/2 py-2" onClick={() => setDeleteTemplates(false)}>
						Anuluj
					</button>
				</div>
			</div>
		</>
	)
}

export function TemplateDetails() {
	const [templateInfo, setTemplateInfo] = useState("")
	const [templateBase, setTemplateBase] = useState("")
	const [loader, setLoader] = useState(true)
	const [userLogin, setUserLogin] = useState("")
	const [userData, setUserData] = useState([])
	const [permission, setPermission] = useState(false)
	const [deleteTemplates, setDeleteTemplates] = useState(false)
	const { dataToken } = useCheckToken(token)
	const navigate = useNavigate()

	console.log(templateInfo)

	let { templateId } = useParams()
	const templateRef = ref(db, `Template/box/${templateId}`)

	const [report, setReport] = useState(false)

	if (document.title === undefined) {
		document.title = "Ładowanie.."
	} else {
		document.title = templateBase && templateBase.title
	}

	async function checkTokenLoginUser() {
		if (dataToken.code == 200) return setUserLogin(dataToken.message.username)
		if (dataToken.code === 401) return setUserLogin("")
	}

	async function loadTemplateData() {
		const userRef = ref(db, `Accounts/${userLogin}`)

		try {
			const userDataSnapshot = await get(userRef)
			const templateDataSnapshot = await get(templateRef)
			const templateSnapshot = templateDataSnapshot.val()
			if (userDataSnapshot.exists() && userLogin !== "") {
				setUserData(userDataSnapshot.val())
			}

			setTemplateBase(templateSnapshot)

			fetch(
				`https://discord.com/api/v9/guilds/templates/${
					templateSnapshot && templateSnapshot.link.split("https://discord.new/")[1]
				}`
			)
				.then(res => res.json())
				.then(data => {
					setTemplateInfo(data)
				})
				.catch(err => console.log(err))
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		checkTokenLoginUser()
		loadTemplateData()
	}, [templateId, userLogin, permission, dataToken])

	useEffect(() => {
		if (userData.permission === "a") return setPermission(false)
		if (userData.permission === "ab") return setPermission(true)
	}, [userData])

	useEffect(() => {
		if (templateInfo) {
			setLoader(false)
		}
		if (templateInfo.code === 10057) {
			remove(templateRef)
		}
	}, [templateInfo])

	useEffect(() => {
		if (templateBase === null) {
			document.title = "Szablon nie istnieje"
			setLoader(false)
		}
	}, [templateBase])

	const statsHandler = async id => {
		console.log(id)
		const statsRef = ref(db, `Stats`)
		const templateRef = ref(db, `Template/box/${id}`)

		try {
			const statSnapshot = await get(statsRef)
			const templateSnapshot = await get(templateRef)

			update(statsRef, { clickButtonUse: statSnapshot.val().clickButtonUse + 1 })
			update(templateRef, {
				clickButtonUse:
					templateSnapshot.val().clickButtonUse === undefined ? 1 : templateSnapshot.val().clickButtonUse + 1,
			})
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<>
			<Navbar />
			<Loader loader={loader} />
			<Toaster
				toastOptions={{
					success: {
						style: {
							background: "#418d41",
							color: "white",
							padding: "12px",
						},
					},

					error: {
						style: {
							background: "#D32F2F",
							color: "white",
							padding: "12px",
						},
					},
				}}
				reverseOrder={false}
			/>
			<div
				className={`${
					templateBase === null || templateInfo.code === 10057 ? "block" : "hidden"
				} show h-[calc(100%-4.5rem)] w-full flex items-center justify-center max-lg:flex-col max-lg:text-center px-5`}>
				<div className="">
					<h2 className="text-3xl text-orange-custom font-black">Szablon nie istnieje</h2>
					<p className="mt-3">
						Wyszukany szablon <span className="font-black">{templateId}</span> nie istnieje bądź został usunięty.
					</p>
					<p className="py-1">Widzisz ten szablon bo nasz system nie zdążył przeskanować tego szablonu.</p>
					<p>
						Skanowanie wszystkich szablonów odbywa sie codziennie o{" "}
						<span className="text-orange-custom font-bold">1.00.</span>
					</p>
					<div className="mt-5">
						<a href="/templates" className="bg-orange-custom py-2 px-5 rounded-lg">
							Szablony discord
						</a>
					</div>
				</div>
				<img src={templateNotFound} alt="template not found" className="w-[30rem] max-md:mt-2" />
			</div>
			<DeleteTemplate
				deleteTemplates={deleteTemplates}
				templateId={templateId}
				setDeleteTemplates={setDeleteTemplates}
				userData={userData}
				navigate={navigate}
				templateInfo={templateInfo}
				templateRef={templateRef}
			/>
			<ReportTemplate
				templateId={templateId}
				report={report}
				setReport={setReport}
				dataToken={dataToken}
				templateInfo={templateInfo}
			/>
			<main
				className={`${loader ? "hidden" : "block"} ${
					templateBase === null || templateInfo.code === 10057 ? "hidden" : "block"
				} show px-10 max-lg:px-5 mb-16 relative`}>
				<section className="template-info flex flex-col items-center mt-10">
					<div className="flex flex-col items-start w-full max-w-[70rem] my-2">
						<h2 className="text-4xl font-semibold max-sm:text-3xl">Szablon</h2>
						<p className={`text-xs ${templateBase && templateBase.method == "shizeclone" ? "block" : "hidden"} `}>
							*Szablon tego serwera pochodzi z{" "}
							<a href="https://shizeclone.eu" className="text-orange-custom font-medium">
								shizeclone.eu
							</a>{" "}
						</p>
					</div>
					<div className="w-full mx-auto">
						<div className="flex max-md:flex-col items-center gap-2 w-full max-w-[70rem] mx-auto">
							<div className="template-info-box px-5 py-5 flex  items-center bg-main-color-popup  rounded-lg w-full">
								<div className="template-info-text flex justify-between w-full flex-col max-sm:flex-wrap items-start">
									<div className="title w-full relative">
										<h2 className="text-3xl max-sm:text-2xl">{templateBase && templateBase.title}</h2>
										<div className="absolute flex gap-1 items-center top-1/2 translate-y-[-50%] right-0 cursor-pointer "></div>
									</div>
									<p className="mt-1 text-xl max-sm:text-lg">{templateBase && templateBase.description}</p>
									<div className="info-template flex flex-wrap gap-2 mt-2">
										<p className="flex items-center px-2 border-[2px] border-orange-custom rounded-full">
											<span className="w-3 h-3 bg-orange-custom rounded-full mr-1"></span>
											{templateBase && templateBase.categories}
										</p>
										<p className="flex items-center px-2 border-[2px] border-orange-custom rounded-full">
											<span className="w-3 h-3 bg-orange-custom rounded-full mr-1"></span>
											{templateInfo.created_at && templateInfo.created_at.split("T")[0].split("-").reverse().join(".")}
										</p>
										<p className="flex items-center px-2 border-[2px] border-orange-custom rounded-full">
											<span className="w-3 h-3 bg-orange-custom rounded-full mr-1"></span>
											{templateInfo.usage_count} użyć
										</p>
										<div
											className={`${
												(templateBase && templateBase.serverLink) || (templateBase && templateBase.server)
													? "flex"
													: "hidden"
											} items-center bg-dark-custom rounded-full px-1 transition-colors hover:bg-[#242b35]`}>
											{templateInfo.creator && (
												<img
													src={templateBase && templateBase.avatarServer}
													className="w-4  rounded-full"
													alt="avatar server"
												/>
											)}
											<Tooltip id="my-tooltip" />
											<a
												href={`${templateBase && templateBase.serverLink}`}
												data-tooltip-id="my-tooltip"
												data-tooltip-content="Szablon należący do serwera">
												<p className="px-1">{templateBase && templateBase.server}</p>
											</a>
										</div>
										<div className="flex items-center bg-dark-custom rounded-full px-1 transition-colors hover:bg-[#242b35]">
											{templateInfo.creator && (
												<img
													src={
														templateInfo.creator.id === undefined || templateInfo.creator.avatar === null
															? userLogoDiscord
															: `https://cdn.discordapp.com/avatars/${templateInfo.creator.id}/${templateInfo.creator.avatar}.webp`
													}
													className="w-4  rounded-full"
													alt="avatar user"
												/>
											)}
											<a href={`/user/${templateInfo.creator && templateInfo.creator.id}`}>
												<p className="px-1">Twórca: {templateInfo.creator && templateInfo.creator.global_name}</p>
											</a>
										</div>
									</div>
									<a href={"https://discord.new/" + templateInfo.code} onClick={() => statsHandler(templateBase.ID)}>
										<button className="mt-2 py-2 px-4 bg-orange-custom tracking-wide text-lg rounded-lg animate-pulse">
											Użyj szablonu
										</button>
									</a>
								</div>
							</div>
							<div className="flex flex-col justify-center px-5 py-2 rounded-lg max-md:w-full max-lg:min-w-60  min-w-72 bg-main-color-popup h-48">
								<p className="text-gray-400">Statystyki kanałów/ról</p>
								<Tooltip id="my-tooltip" />
								<ul className="my-2">
									<li className="flex justify-between">
										Kanały tekstowe:{" "}
										<span>
											<CountUp
												start={loader}
												end={
													templateInfo.serialized_source_guild &&
													templateInfo.serialized_source_guild.channels.filter(count => count.type === 0).length
												}
												duration={4}
											/>
										</span>
									</li>
									<li className="flex justify-between">
										Kanały głosowe:{" "}
										<span>
											<CountUp
												start={loader}
												end={
													templateInfo.serialized_source_guild &&
													templateInfo.serialized_source_guild.channels.filter(count => count.type === 2).length
												}
												duration={4}
											/>
										</span>
									</li>
									<li className="flex justify-between">
										Kategorie:{" "}
										<span>
											<CountUp
												start={loader}
												end={
													templateInfo.serialized_source_guild &&
													templateInfo.serialized_source_guild.channels.filter(count => count.type === 4).length
												}
												duration={4}
											/>
										</span>
									</li>
									<li className="flex justify-between">
										Role:{" "}
										<span>
											<CountUp
												start={loader}
												end={templateInfo.serialized_source_guild && templateInfo.serialized_source_guild.roles.length}
												duration={4}
											/>
										</span>
									</li>
									<li className="flex justify-between">
										Szablon z serwera:{" "}
										<a
											data-tooltip-id="my-tooltip"
											data-tooltip-content={
												templateInfo.serialized_source_guild && templateInfo.serialized_source_guild.name
											}>
											<p>Najedź</p>
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="flex items-center max-md:items-start gap-4 max-w-[70rem] mx-auto bg-orange-custom mt-5 rounded-lg p-4">
							<span className="material-symbols-outlined text-5xl">info</span>
							<div className="">
								<p className="text-2xl max-md:text-xl max-md:font-bold font-medium">
									Szablon należy do twojego serwera?
								</p>
								<p>
									Skontaktuj się z nami na{" "}
									<a href="https://discord.gg/M5eVcMdWRd" className="underline">
										discordzie
									</a>{" "}
									. Możliwość zablokowania klonowania/dodawanie twojego serwera: 25 zł/na zawsze.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="template-details flex flex-col items-center mt-5  max-w-container mx-auto">
					<div className="flex items-center justify-between w-full max-w-[70rem] my-2">
						<h2 className="text-4xl font-semibold max-sm:text-3xl">Podgląd szablonu</h2>
						<div className="flex items-center gap-2">
							<button
								className={`items-center gap-1 ${
									permission ? "flex" : "hidden"
								} text-red-500 hover:text-red-600 transition-colors`}
								onClick={() => setDeleteTemplates(true)}>
								<span className="material-symbols-outlined text-xl">delete</span>
								<p>Usuń</p>
							</button>
							<button className="flex items-center gap-1" onClick={() => setReport(true)}>
								<span className="material-symbols-outlined text-xl">flag</span>
								<p>Zgłoś</p>
							</button>
						</div>
					</div>
					<div className="template-details-main max-sm:w-full w-full max-w-[70rem] mx-auto max-md:flex-col bg-main-color-popup flex justify-between  px-5 py-5 mb-10 rounded-lg">
						<div className="template-details-channel w-1/2 max-md:w-full">
							<h2 className="my-2 text-2xl">
								Kanały ({templateInfo.serialized_source_guild && templateInfo.serialized_source_guild.channels.length})
							</h2>
							<ul>
								{templateInfo.serialized_source_guild &&
									templateInfo.serialized_source_guild.channels.map((channel, index) => (
										<li
											key={index}
											className={
												channel.type === 4 ? "flex items-center my-1 text-lg text-gray-300" : "flex items-center my-0.5"
											}>
											{channel.type === 4 && (
												<span className="material-symbols-outlined text-2xl text-gray-300">expand_more</span>
											)}
											{channel.type === 2 && (
												<span className="material-symbols-outlined text-gray-400 ml-6 mr-2 text-2xl">volume_up</span>
											)}
											{channel.type === 0 && <p className="text-gray-400 text-2xl ml-6 mr-2">#</p>}

											<span>{channel.name}</span>
										</li>
									))}
							</ul>
						</div>
						<div className="template-details-role w-1/2 max-md:w-full">
							<h2 className="my-2 text-2xl">
								Role ({templateInfo.serialized_source_guild && templateInfo.serialized_source_guild.roles.length})
							</h2>
							<ul className="flex items-start flex-wrap">
								{templateInfo.serialized_source_guild &&
									templateInfo.serialized_source_guild.roles.map((roles, index) => (
										<li
											key={index}
											className="px-2 m-1 rounded-full flex items-center overflow-hidden"
											style={{
												borderColor: `#${roles.color.toString(16)}`,
												borderWidth: "2px",
												borderStyle: "solid",
											}}>
											<span
												className="w-3 h-3  rounded-full mr-1"
												style={{
													backgroundColor: `${roles.color === 0 ? "white" : `#${roles.color.toString(16)}`}`,
													height: "0.75rem",
													width: "0.75rem",
												}}></span>
											{roles.name}
										</li>
									))}
							</ul>
						</div>
					</div>
				</section>
			</main>
			<div className={`${templateBase !== null ? "absolute bottom-0 left-1/2 -translate-x-1/2" : "hidden"}`}>
				<Footer />
			</div>
		</>
	)
}
