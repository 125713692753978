

import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export function RedirectUser() {
	const token = localStorage.getItem("token")
	const navigate = useNavigate()

	useEffect(() => {
		fetch(process.env.REACT_APP_CHECK_TOKEN, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(data => {
				if (data.code === 200) {
					navigate("/panel")
				}
			}, [])
	})
}
