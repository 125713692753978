import { Navbar, Footer } from "../App"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import passwordFogotImg from "../../img/requestReset.png"
import toast, { Toaster } from "react-hot-toast"
import ErrorModal from "../hook/ModalErr"
import { Tooltip as ReactToolTip } from "react-tooltip"

export function RequestResetPassword() {
	const [email, emailChange] = useState("")
	const [paragraphErrText, setparagraphErrText] = useState("")
	const [errModal, setErrModal] = useState(false)
	const [succesSend, setSuccesSend] = useState(false)

	function sendDate() {
		const regex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/

		if (email === "") return setparagraphErrText("Wpisz swój email"), setErrModal(true)

		if (!regex.test(email)) {
			setErrModal(true)
			setparagraphErrText("Email jest nieprawidłowy")
			return
		}

		fetch(process.env.REACT_APP_FORGOT_PASSWORD, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
			},
			body: JSON.stringify({ email }),
		})
			.then(res => res.json())
			.then(data => {
				if (data.code === 400)
					return setparagraphErrText("Email nie jest przepisany do zadnego konta"), setErrModal(true)
				if (data.code === 200)
					return toast.success("Email z instrukcją resetowania hasła został wysłany"), setSuccesSend(true)
			})
	}

	useEffect(() => {
		if (errModal !== false) {
			setTimeout(() => {
				setErrModal(false)
			}, 2500)
		}
	}, [errModal])

	return (
		<>
			<Navbar />
			<Toaster position="top-center" reverseOrder={false} />
			<main>
				<ErrorModal errModal={errModal} paragraphErrText={paragraphErrText} />

				<section className="flex flex-col items-center absolute top-1/2 translate-y-[-50%] h-80  w-full px-4 ">
					<div className="flex max-sm:w-full max-sm:flex-col h-full items-center bg-main-color-popup rounded-lg py-5 px-5 gap-10 shadow-xl ">
						<img src={passwordFogotImg} alt="image passwordRequest" className="w-72 h-64 max-sm:hidden" />
						<div className="w-80 max-sm:w-11/12 flex flex-col justify-around items-start h-full">
							<div className="flex gap-1">
								<h2 className="text-3xl font-semibold">Resetowanie hasła</h2>

								<ReactToolTip id="my-tooltip" style={{ width: "20rem" }} />
								<span
									className="material-symbols-outlined text-xl"
									data-tooltip-id="my-tooltip"
									data-tooltip-content={
										"Jeśli resetujesz hasło a konto zarejestrowałeś za pomoca discorda to daj email swojego konta discord"
									}>
									info
								</span>
							</div>
							<div className="mt-5 w-full">
								<div className="relative z-0">
									<input
										type="Email"
										id="Email"
										name="email"
										value={succesSend ? "" : email}
										className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2  appearance-none  border-gray-600  focus:border-orange-custom focus:outline-none focus:ring-0  peer"
										placeholder=" "
										onKeyDown={e => {
											if (e.key === "Enter") sendDate()
										}}
										onChange={e => emailChange(e.target.value)}
									/>
									<label
										htmlFor="Email"
										className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-custom peer-focus:dark:text-orange-custom peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
										Email
									</label>
								</div>
							</div>
							<div className="flex flex-col items-start mt-3">
								<p>
									Nie masz konta?{" "}
									<Link to="/register" className="underline text-orange-custom">
										Zarejestruj się
									</Link>
								</p>
								<div className="flex items-center gap-2 mt-3">
									<button
										className={`py-2 px-4 rounded-lg ${
											succesSend ? "bg-disabled-custom cursor-not-allowed" : "bg-orange-custom"
										}`}
										disabled={succesSend}
										onClick={sendDate}>
										Resetuj
									</button>
									<Link
										to="/login"
										className={`py-2 px-4 rounded-lg bg-orange-custom ${succesSend ? "block" : "hidden"}`}>
										Zaloguj się
									</Link>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<div className="absolute bottom-0 flex justify-center w-full">
				<Footer />
			</div>
		</>
	)
}
