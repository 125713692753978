import { Link, useNavigate, useParams } from "react-router-dom"
import { Navbar, Footer } from "../../App"
import { getDatabase, onValue, ref, update, get } from "firebase/database"
import { useState, useEffect } from "react"
import userLogoDiscord from "../../../img/user-logo.webp"
import wallSecurity from "../../../img/wallsecurity.png"
import toast, { Toaster } from "react-hot-toast"
import Loader from "../../hook/Loader"
import CountUp from "react-countup"
import useCheckToken from "../../hook/checkTokenHook"

const db = getDatabase()
const token = localStorage.getItem("token")

export function ProfileServers() {
	const { idServer } = useParams()
	const [data, setData] = useState([])
	const [idUser, setIdUser] = useState("")
	const [userList, setUserList] = useState([])
	const [loader, setLoader] = useState(true)
	const [isUserLogin, setUserLogin] = useState("")
	const [serverPermission, setServerPermission] = useState(false)
	const [serverData, setServerData] = useState([])
	const { dataToken } = useCheckToken(token)
	const navigate = useNavigate()
	const profileUserAdd = ref(db, `ProfileServer/${idServer}`)
	const isProfileAccountUser = ref(db, `Accounts/${isUserLogin}/ProfileServer/${idServer}`)

	//do niedzialajacych

	const [linkDontWork, setLinkDontWork] = useState(false)
	const [newLink, setNewLink] = useState("")
	const [linkErr, setLinkErr] = useState("")

	useEffect(() => {
		if (dataToken.code === 200) return setUserLogin(dataToken.message.username)
		if (dataToken.code === 401) return navigate("/")
	}, [dataToken])

	const loadTemplateInfo = async () => {
		try {
			const isProfile = await get(isProfileAccountUser)

			if (!isProfile.exists()) return setServerPermission(true)
			const data = await get(profileUserAdd)
			const dataProfile = data.val()
			const discordServerInfo = await fetch(
				`https://discord.com/api/v10/invites/${dataProfile.link.split("https://discord.gg/")[1]}?with_counts=true`
			)
			const discordInfoData = await discordServerInfo.json()

			setServerData(discordInfoData)

			setData(dataProfile)

			if (dataProfile && dataProfile.userServer) {
				const promises = dataProfile.userServer.map(key => {
					const dataAccountRef = ref(db, `User/${key}`)
					return get(dataAccountRef).then(snapshot => {
						return snapshot.val() === null ? key : snapshot.val()
					})
				})

				Promise.all(promises).then(result => {
					setUserList(result)
				})
			}
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		if (isUserLogin !== "") {
			loadTemplateInfo()
		}
	}, [isUserLogin])

	useEffect(() => {
		if (data && serverData) {
			console.log(serverData.length)
			setTimeout(() => {
				setLoader(false)
			}, 300)
		}

		if (serverData.code === 10006) {
			setLinkDontWork(true)
		}

		console.log(serverData)
	}, [data, serverData])

	function addUser(e) {
		e.preventDefault()

		const isUserArrayExists = data.userServer === undefined ? [] : data.userServer

		const checkUserExists = isUserArrayExists.some(user => user === idUser)

		const re = /^[0-9]+$/

		if (idUser === "") return toast.error("Pole nie może byc puste")
		if (!re.test(idUser)) return toast.error("ID użytkownika jest nie poprawne")
		if (checkUserExists) return toast.error("Użytkownik już istnieje w bazie")

		const userServerUpdate = { userServer: [idUser] }
		get(profileUserAdd).then(snapshot => {
			if (snapshot.exists()) {
				const userServerArray = Array.isArray(data.userServer) ? data.userServer : []

				const addNewUser = {
					userServer: [...userServerArray, idUser],
				}
				update(profileUserAdd, addNewUser)
			} else {
				update(profileUserAdd, userServerUpdate)
			}
			toast.success("Użytkownik został dodany do bazy danych.")
			loadTemplateInfo()
		})
	}

	function deleteUser(user) {
		const userExists = typeof user === "object" ? user.userId : user
		const deleteUserRef = ref(db, `ProfileServer/${idServer}`)

		if (data.userServer) {
			const userToDelete = data.userServer.filter(user => user !== userExists)

			const newArrayUsers = {
				userServer: userToDelete,
			}

			update(deleteUserRef, newArrayUsers)
			loadTemplateInfo()
		}
	}

	const checkNewLink = async () => {
		const newLinkRef = ref(db, `Accounts/${isUserLogin}/ProfileServer/${idServer}`)
		const newLinkGlobalRef = ref(db, `ProfileServer/${idServer}`)

		try {
			if (!newLink === "") return setLinkErr("Może coś wpisz najpierw, co?")
			if (!newLink.includes("discord.gg")) return setLinkErr("To nie jest link do serwera..")

			const linkToCode = newLink.split("https://discord.gg/")[1]
			const newLinkServer = await fetch(`https://discord.com/api/v10/invites/${linkToCode}`)
			const newLinkServerData = await newLinkServer.json()

			if (newLinkServerData.code === 10006) return setLinkErr("Link do serwera jest nieprawidłowy")

			const profileData = await get(newLinkGlobalRef)

			console.log(profileData.val().link)

			fetch(process.env.REACT_APP_CHANGE_LINK, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
				},
				body: JSON.stringify({ oldLink: profileData.val().link, newLink: newLink }),
			})

			update(newLinkRef, { link: newLink })
			update(newLinkGlobalRef, { link: newLink })
			setLinkErr("")
			setLinkDontWork(false)
			loadTemplateInfo()
			toast.success("Prawidłowo zmieniłeś link do swojego serwera")
			update(newLinkGlobalRef, { deleteDate: null })
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<>
			<Navbar />
			<Toaster position="top-center" reverseOrder={false} />
			<Loader loader={loader} />
			{linkDontWork && (
				<div className="fixed top-0 left-0 w-full h-full bg-black-transparent overflow-y-hidden z-50"></div>
			)}

			<main className={`${loader ? "hidden" : "block"} show container mx-auto px-2 mb-24`}>
				<div
					className={`bg-main-color-popup ${
						serverPermission ? "flex" : "hidden"
					} max-w-96 w-full flex-col items-center text-center rounded-lg py-5 px-5 shadow-xl absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] "`}>
					<img src={wallSecurity} alt="brak dostępu" className="w-64" />
					<h2 className="text-lg my-2">
						Nie masz do tego <span className="font-semibold text-orange-custom">dostępu</span>
					</h2>
					<p>Nie masz dostępu do tego serwera, albo nie jest twój albo nie istnieje.</p>
				</div>
				<div
					className={`${
						linkDontWork ? "block" : "hidden"
					} fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-popup-custom w-[30rem] py-3 px-2 rounded-lg z-50 max-md:w-11/12`}>
					<h2 className="text-xl font-black">Link do twojego serwera wygasł.</h2>
					<p>Aby zapewnić uzytkownikom, że link do twojego serwera jest ciągle aktywny musisz go zmienić bo wygasł.</p>
					<div className="mt-2">
						<p className="font-black">Informacja</p>
						<p>
							Podmiana wszystkich linków do serwerów w każdym szablonie może trochę zająć. Zazwyczaj nie przekracza to
							2h.
						</p>
					</div>
					<div className="flex flex-col mt-2">
						<label htmlFor="link" className="font-black">
							Wprowadź link do serwera
						</label>
						<input
							type="text"
							id="link"
							className="bg-input-custom py-2 px-2 rounded-lg"
							placeholder="link do serwera"
							onChange={e => setNewLink(e.target.value)}
						/>
						<p className="text-sm text-red-500">{linkErr}</p>
						<div className="mt-2">
							<button className="w-fit bg-orange-custom py-1 px-2 rounded-lg mr-2" onClick={checkNewLink}>
								Zaktualizuj
							</button>
							<button className="w-fit bg-red-500 py-1 px-2 rounded-lg">
								<Link to="/panel">Anuluj</Link>
							</button>
						</div>
					</div>
				</div>
				<section className={`mt-10 ${serverPermission ? "hidden" : "block"} flex flex-col justify-center`}>
					<div className="flex flex-col items-center">
						<div className="w-7/12 max-xl:w-11/12 my-2 max-lg:w-full">
							<h2 className="text-4xl font-semibold">Profil serwera</h2>
						</div>
						<div className="template-info-box px-5 flex items-start justify-between gap-2 py-5 bg-main-color-popup w-7/12 max-xl:w-11/12 rounded-lg max-lg:w-full">
							<div className="template-info-text flex items-start gap-2">
								<img src={`${data && data.avatar}`} alt="logo server" className="w-28 max-md:w-14" />
								<div className="flex flex-col">
									<div className="title w-full relative">
										<h2 className="text-3xl max-md:text-xl">{data && data.name}</h2>
									</div>
									<p className="mt-1 text-xl max-md:text-xs max-md:text-orange-custom">{data && data.link}</p>
									<div className="info-template flex flex-wrap gap-2 mt-2">
										<p className="flex items-center px-2 border-[2px] border-orange-custom rounded-full">
											<span className="w-3 h-3 bg-orange-custom rounded-full mr-1"></span>
											{data && data.id}
										</p>
									</div>
								</div>
							</div>
							<div className="flex gap-1 items-center">
								<div className="w-3 h-3 bg-green-500 rounded-full"></div>
								<p className="font-black">
									<CountUp start={0} end={serverData && serverData.approximate_presence_count} />
								</p>
								<p>online</p>
							</div>
						</div>
						<div className="bg-main-color-popup w-7/12 mt-3 rounded-lg p-4 max-xl:w-11/12 max-lg:w-full">
							<h2 className="text-xl font-semibold">Opis serwera</h2>
							<p>
								{serverData.guild && serverData.guild.description === null
									? "Serwer  nie posiada jeszcze swojego opisu"
									: serverData.guild && serverData.guild.description}
							</p>
						</div>
					</div>
				</section>
				<section className={`flex justify-center ${serverPermission ? "hidden" : "block"} max-md:flex-col flex-wrap`}>
					<div className="w-7/12 max-xl:w-11/12 my-2 max-lg:w-full flex flex-col">
						<div className="flex gap-5 max-md:gap-0 max-md:flex-col">
							<div className="w-96 py-5 px-5  bg-main-color-popup rounded-lg max-md:w-full">
								<h2 className="text-2xl font-semibold w-96">Dodaj użytkownika</h2>
								<p>Przydziel użytkownika do serwera aby zaliczało statystyki serwera.</p>

								<form onSubmit={addUser}>
									<div class="relative z-0 mt-3">
										<input
											type="text"
											id="login"
											name="username"
											className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2  appearance-none  border-gray-600  focus:border-orange-custom focus:outline-none focus:ring-0  peer"
											placeholder=""
											onChange={e => setIdUser(e.target.value)}
										/>
										<label
											for="login"
											className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-custom peer-focus:dark:text-orange-custom peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
											ID konta discord
										</label>
										<button className="bg-orange-custom py-0.5 px-4 mt-2 rounded-lg">Dodaj</button>
									</div>
								</form>
							</div>
							<div className="w-7/12 py-5 px-5  bg-main-color-popup rounded-lg max-md:w-full">
								<h2 className="text-2xl font-semibold">Statystki Serwera</h2>
								<div className="">
									<ul>
										<li>
											Stworzenie profilu: <span className="font-semibold">{data && data.dateCreate}</span>
										</li>
										<li>
											Ilość szablonów: <span className="font-semibold">{data && data.templatesCount}</span>
										</li>
										{data && (
											<li>
												Ilość użytkowników:{" "}
												<span className="font-semibold">
													{data.userServer === undefined ? "0" : data.userServer.length}
												</span>
											</li>
										)}
										<li>
											Właściciel serwera: <span className="font-semibold">{data && data.owner}</span>
										</li>
										<li>
											Ilość osób na serwerze:{" "}
											<span className="font-semibold">{serverData && serverData.approximate_member_count} osób</span>
										</li>
										<li>
											ID serwera discord: <span className="font-semibold">{serverData && serverData.guild_id}</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="flex items-start gap-5 max-md:gap-0 max-md:flex-col mt-3">
							<div className="w-96 max-md:w-full">
								<div className="w-96 max-h-96 overflow-auto py-5 px-5  bg-main-color-popup rounded-lg max-md:w-full ">
									<h2 className="text-2xl font-semibold ">Użytkownicy Serwera</h2>
									{userList &&
										userList.map(user => (
											<div className="relative flex gap-2 my-2">
												<img
													src={`${
														typeof user === "object"
															? `https://cdn.discordapp.com/avatars/${user.userId}/${user.avatar}.webp`
															: userLogoDiscord
													}`}
													alt="userlogo discord"
													className="w-16"
												/>
												<div className="">
													<p>{typeof user === "object" ? user.username : user}</p>
													<p className="mt-1">{typeof user === "object" ? user.userId : "Nie istnieje w bazie"}</p>
												</div>
												<span
													className="material-symbols-outlined absolute right-0 cursor-pointer"
													onClick={() => deleteUser(user)}>
													delete
												</span>
											</div>
										))}
									<p>{userList && userList.length === 0 ? "Brak użytkowników serwera" : ""}</p>
								</div>
							</div>
							<div className="w-full py-5 px-5  bg-main-color-popup max-md:mt-0 rounded-lg">
								<h2 className="text-2xl font-semibold">Logi serwera</h2>
								<div className="flex  gap-1">
									<span className="material-symbols-outlined">handyman</span>
									<p>Sekcja w trakcie prac...</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<div className="absolute bottom-0 left-1/2 -translate-x-1/2">
				<Footer />
			</div>
		</>
	)
}
