const { useState, useEffect } = require("react")

const useCheckToken = token => {
	const [data, setData] = useState([])

	const check = async () => {
		const fetchToken = await fetch(process.env.REACT_APP_CHECK_TOKEN, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		const tokenData = await fetchToken.json()

		setData(tokenData)
	}

	useEffect(() => {
		check()
	}, [token])

	return { dataToken: data }
}

export default useCheckToken
