import { Footer, Navbar } from "../App"
import { useEffect, useState, useRef } from "react"
import "../../database"
import {
	getDatabase,
	ref,
	onValue,
	set,
	update,
	get,
	query,
	limitToLast,
	limitToFirst,
	startAt,
	startAfter,
	orderByKey,
	endBefore,
} from "firebase/database"
import toast, { Toaster } from "react-hot-toast"
import { Tooltip } from "react-tooltip"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import Loader from "../hook/Loader"
import LoaderAnimation from "../hook/LoaderAnimation"
import { UserNotLogin } from "../hook/UserIsNotLogin"
import useInfiniteScroll from "../hook/infinitiveScrollHook"
/* global grecaptcha */

let db = getDatabase()

function Menu({ setMenu, menu, menuRef, setReserveTemplate }) {
	return (
		<div className="menu flex flex-col justify-center  relative">
			<button onClick={() => setMenu(!menu)}>
				<span className="material-symbols-outlined top-1/2">lists</span>
			</button>
			<div
				className={`elements ${
					menu === false ? "hidden" : "block"
				}  bg-input-main-color rounded-lg absolute top-12 w-64`}
				ref={menuRef}>
				<a href="/create">
					<div className="element  flex justify-between items-center px-2 py-4 cursor-pointer">
						<div className="text flex gap-1">
							<span className="material-symbols-outlined">add</span>
							<p>Dodaj szablon</p>
						</div>
						<span className="material-symbols-outlined chevron-right transition-transform">chevron_right</span>
					</div>
				</a>
				<hr />
				<div
					className="element flex justify-between items-center px-2 py-4 cursor-pointer"
					onClick={() => {
						setReserveTemplate(true)
						setMenu(false)
					}}>
					<div className="text flex gap-1">
						<span className="material-symbols-outlined">block</span>
						<p>Zastrzeż szablon</p>
					</div>
					<span className="material-symbols-outlined chevron-right transition-transform">chevron_right</span>
				</div>
			</div>
		</div>
	)
}

function Card({ template, categoryFilters }) {
	return (
		<div
			className={`card show     bg-main-color-popup relative w-80 rounded-xl shadow-lg overflow-hidden mt-4 flex flex-col justify-between h-60 border-t-2 transition-colors border-orange-custom hover:border-[#10b6a8]`}>
			<div className="px-2 pt-3 flex-grow">
				<h2 className="text-2xl font-semibold text-orange-custom mb-2">{template.title}</h2>
				<p className="">{template.description === "" ? "Brak opisu szablonu" : template.description}</p>
			</div>
			<div className="px-2 pb-2">
				<div className="text-sm text-gray-400">
					<p className={`${categoryFilters === template.categories ? "font-black" : ""}`}>
						Kategoria: {template.categories}
					</p>
					<p>Data opublikowania: {template.dateCreate}</p>
				</div>
				<div className="w-full mt-1">
					<a href={`/templates/${template.ID}`} target="_blank" rel="noopener noreferrer">
						<button className="bg-orange-custom transition-colors hover:bg-[#167c74] text-white font-bold py-2 px-4 rounded w-full">
							POKAŻ SZABLON
						</button>
					</a>
				</div>
			</div>
		</div>
	)
}

function ReserveTemplatePopup({
	reserveTemplate,
	setReserveTemplate,
	reserveTemplateValue,
	setReserveTemplateValue,
	reserveTemplateValueErr,
	setReserveTemplateValueErr,
	reserveTemplates,
}) {
	const [nameServer, setNameServer] = useState("")
	const [isUserLogin, setUserLogin] = useState("")
	const userRef = ref(db, "Accounts/" + isUserLogin)
	const checkUserLogin = localStorage.getItem("token")

	async function getReserveName() {
		await get(userRef).then(snapshot => {
			setNameServer(snapshot.val().reserveServer)
		})
	}

	if (reserveTemplate) {
		getReserveName()
	}

	useEffect(() => {
		fetch(process.env.REACT_APP_CHECK_TOKEN, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${checkUserLogin}`,
			},
		})
			.then(res => res.json())
			.then(data => {
				if (data.code === 200) return setUserLogin(data.message.username)
				if (data.code === 401) return setUserLogin("")
			})
	}, [])

	async function reserveTemplateSend() {
		const dateID = Date.now()
		const reserveRef = ref(db, "ReserveTemplate/" + dateID)

		const dataReserveTemplate = {
			name: reserveTemplateValue,
		}
		const userRefSnapshot = await get(userRef)

		try {
			if (userRefSnapshot.val().reserveServer !== null && userRefSnapshot.val().reserveServer !== undefined) {
				setReserveTemplate(userRefSnapshot.val().reserveServer)
				setReserveTemplate(false)
				toast.error("Możesz zastrzec tylko jeden szablon")
				return
			}

			if (reserveTemplateValue === "") return setReserveTemplateValueErr("(Uzupełnij)")

			const UserServerUpdate = {
				reserveServer: reserveTemplateValue,
			}

			update(userRef, UserServerUpdate)
			setReserveTemplateValueErr("")
			setReserveTemplate(false)
			set(reserveRef, dataReserveTemplate)
			toast.success(`Szablon serwera został zastrzeżony.`)
		} catch (err) {
			console.log(err)
		}
	}

	function updateServer(nameServer) {
		update(userRef, { reserveServer: null })
		setNameServer("")
		setReserveTemplateValue("")
	}
	return (
		<>
			<div
				className={`reserve-template ${
					reserveTemplate === false ? "hidden" : "block"
				} bg-popup-custom fixed top-2/4 left-2/4 translate-x-[-50%] translate-y-[-50%] w-[30rem] rounded-xl max-xl:w-[50%] 
				max-md:min-w-[80%] overflow-auto z-[150]`}
				ref={reserveTemplates}>
				<UserNotLogin isUserLogin={isUserLogin} />
				<div className="popup-main flex justify-between text-xl p-4">
					<h2>Zastrzeż szablon</h2>
					<button>
						<span
							className="material-symbols-outlined"
							onClick={() => {
								setReserveTemplate(false)
							}}>
							close
						</span>
					</button>
				</div>
				<div className="border border-b border-input-border-color"></div>
				<div className="p-4">
					<div className="item">
						<div className="flex items-center gap-2">
							<label htmlFor="" className="text-lg">
								Dokładna nazwa serwera
							</label>
							<p className="text-red-600">{reserveTemplateValueErr}</p>
						</div>
						<div className="flex items-center gap-2">
							<input
								type="text"
								className="w-full px-1 py-2 bg-input-custom rounded-lg outline-none "
								onChange={e => setReserveTemplateValue(e.target.value)}
								value={nameServer !== "" ? nameServer : reserveTemplateValue}
							/>
							<button
								className="material-symbols-outlined hover:text-red-500 transition-colors"
								onClick={() => {
									updateServer(nameServer)
								}}>
								<span className="material-symbols-outlined">delete</span>
							</button>
						</div>
					</div>
					<div className="info-resarve">
						<h2 className="mt-3 text-lg font-semibold">Jak to działa?</h2>
						<p>
							Przed dodaniem każdego szablonu bot poprzez API będzie sprawdzał szablon i nazwe wychodzącą szablonu czy
							pokrywa się z zastrzeżoną, jeśli pokrywa to uniemożliwia dodania szablonu.
						</p>
					</div>

					<button className="w-full bg-orange-custom py-2 px-1 rounded-lg mt-3" onClick={() => reserveTemplateSend()}>
						Zastrzeż
					</button>
				</div>
			</div>
		</>
	)
}

function ErrServer({ errLoad }) {
	return (
		<>
			<div
				className={`${
					errLoad ? "block" : "hidden"
				} absolute top-1/2 -translate-y-1/2 w-full flex flex-col items-center text-center`}>
				<h2 className="text-3xl">
					Nastapił <span className="text-orange-custom underline">błąd</span> serwera
				</h2>
				<p>Niestaty ale ta funkcja chwilowo nie działa ponieważ wystąpił błąd serwera..</p>
			</div>
		</>
	)
}

export function TemplateList() {
	const [templates, setTemplates] = useState([])
	const [lastKey, setLastKey] = useState(null)
	const [showOptions, setShowOptions] = useState(false)
	const [menu, setMenu] = useState(false)
	const [reserveTemplate, setReserveTemplate] = useState(false)
	const [loader, setLoader] = useState(true)
	const [reserveTemplateValue, setReserveTemplateValue] = useState("")
	const [reserveTemplateValueErr, setReserveTemplateValueErr] = useState("")
	const [topScroll, setTopScroll] = useState(false)
	const [categoryFilters, setCategoryFilters] = useState("")
	const [searchParams] = useSearchParams()
	const [categoryParams] = useSearchParams()
	const [loadTemplate, setLoadTemplate] = useState(false)
	const templatesRef = query(ref(db, "Template/box"), limitToLast(24))

	//do systemu wyszukiwania

	const [inputValue, setInputValue] = useState("")
	const [templatesSearch, setTemplatesSearch] = useState([])
	const [loadSearch, setLoadSearch] = useState(false)
	const [dataCategory, setDataCategory] = useState([])
	const [loaders, setLoaders] = useState(true)
	const [templateCategory, setTemplateCategory] = useState([])
	const [visibleTemplate, setVisibleTemplate] = useState(25)
	const [totalCategoryTemplate, setTotalCategoryTemplate] = useState(null)
	const [errLoad, setErrLoad] = useState(false)
	const navigate = useNavigate()
	const location = useLocation()

	const categoryParamss = categoryParams.get("category")
	const searchTemplateParams = searchParams.get("search")

	const typeVisible =
		templatesSearch.length > 0 ? templatesSearch : templateCategory.length > 0 ? templateCategory : templates

	const filterRef = useRef(false)
	const menuRef = useRef(null)
	const reserveTemplates = useRef(false)

	const searchSystem = async () => {
		if (location.pathname === "/templates" && inputValue === "") return
		if (inputValue === "") return navigate("/templates"), setTemplatesSearch([])

		if (inputValue !== "") {
			setTotalCategoryTemplate(null)
			setCategoryFilters("")
		}

		if (loadSearch) return
		setLoadSearch(true)

		try {
			const token = await grecaptcha.enterprise.execute("6Le9O38pAAAAAER3-gszQ1xCZMIOH6HS2ip4ey4-", {
				action: "submit",
			})
			const searchSystem = await fetch(process.env.REACT_APP_FUZZY_SEARCH, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
				},
				body: JSON.stringify({ search: inputValue.toLowerCase(), token: token }),
			})

			const searchData = await searchSystem.json()

			if (searchData) setLoadSearch(false)
			if (searchData.code === 403) return toast.error("System wykrył, że jesteś botem")
			setTemplatesSearch(searchData.message)
			if (searchData.message.length === 0) return toast.error("Nie znaleźliśmy takiego szablonu")
			navigate(`/templates?search=${inputValue}`)
		} catch (err) {
			toast.error("Wystąpił nieoczekiwany błąd serwera.")
			setLoadSearch(false)
		}
	}

	const fetchMoreTemplate = async () => {
		if (loadTemplate || !lastKey) return
		setLoadTemplate(true)
		const templateShowMoreRef = query(ref(db, "Template/box"), orderByKey(), endBefore(lastKey), limitToLast(25))
		const snapshot = await get(templateShowMoreRef)

		const templateArray = []
		let newLastKey = null
		snapshot.forEach(childSnapshot => {
			if (newLastKey === null) newLastKey = childSnapshot.key
			templateArray.push(childSnapshot.val())
		})
		setLastKey(newLastKey)
		setTemplates(prevTemplates => [...prevTemplates, ...templateArray.reverse()])
		setLoadTemplate(false)
	}

	const checkLengthCategories = async () => {
		if (dataCategory.length !== 0) return

		try {
			const categoryFetch = await fetch(process.env.REACT_APP_CATEGORY, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
				},
			})

			const categoryData = await categoryFetch.json()

			if (categoryData.code === 200)
				return (
					setDataCategory(categoryData.message),
					setTimeout(() => {
						setLoaders(false)
					}, 100)
				)
		} catch (err) {
			console.log(err)
		}
	}

	const loadCategoriesTemplate = async () => {
		try {
			setShowOptions(false)

			if (categoryParamss === null) return

			if (categoryParamss) setCategoryFilters(categoryParamss)

			setVisibleTemplate(prev => prev + 25)
			setInputValue("")

			const sendCategory = await fetch(process.env.REACT_APP_CATEGORY_LOAD, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
				},
				body: JSON.stringify({
					category: categoryParamss,
					categoryLoad: visibleTemplate,
					isLoaded: templateCategory.length,
				}),
			})

			const sendData = await sendCategory.json()

			const dataToSend = sendData.code !== 500 ? sendData.template : []

			if (sendData.code === 500) {
				setLoader(false)
				setErrLoad(true)
			}

			setTotalCategoryTemplate(sendData.totalTemplate)
			setTemplateCategory(dataToSend)
			setLoader(false)
		} catch (err) {
			console.log(err)
		}
	}

	useInfiniteScroll(() => {
		if (!loadTemplate && !templatesSearch.length > 0) {
			fetchMoreTemplate()
		}
	})

	useInfiniteScroll(() => {
		if (!loadTemplate && !templatesSearch.length > 0 && templateCategory.length > 0) {
			loadCategoriesTemplate()
		}
	})

	useEffect(() => {
		if (categoryParamss || categoryFilters || templateCategory.length > 0) return
		setLoader(true)
		const fetchData = async () => {
			const snapshot = await get(templatesRef)
			const templatesArray = []
			let newLastKey = null
			snapshot.forEach(childSnapshot => {
				if (newLastKey === null) newLastKey = childSnapshot.key
				templatesArray.push(childSnapshot.val())
			})
			setLastKey(newLastKey)
			setTemplates(templatesArray.reverse())
		}

		const handleScroll = () => {
			if (window.scrollY > 300) {
				setTopScroll(true)
			} else {
				setTopScroll(false)
			}
		}

		if (searchTemplateParams) {
			setInputValue(searchTemplateParams)
		}

		fetchData()
		window.addEventListener(`scroll`, handleScroll)
	}, [])

	useEffect(() => {
		if (categoryParamss) return

		if (templates.length > 0) {
			setTimeout(() => {
				setLoader(false)
			}, 200)
		}
	}, [templates])

	useEffect(() => {
		const existsSearchParams = searchParams.has("reserve")
		const resultSearchParams = searchParams.get("reserve")
		if (!loader) {
			if (existsSearchParams) {
				if (resultSearchParams) return setReserveTemplate(true)
			}
		}
	}, [loader])

	useEffect(() => {
		const handleClickOutside = e => {
			if (filterRef.current && !filterRef.current.contains(e.target)) {
				setShowOptions(false)
			}

			if (menuRef.current && !menuRef.current.contains(e.target)) {
				setMenu(false)
			}

			if (reserveTemplates.current && !reserveTemplates.current.contains(e.target)) {
				setReserveTemplate(false)
			}
		}

		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [filterRef, menuRef, reserveTemplates])

	useEffect(() => {
		if (!categoryParamss) return

		if (templateCategory.length === 0 && !errLoad) return setLoader(true)

		if (templateCategory.length > 0) return setLoader(false)
	}, [categoryParamss, templateCategory, categoryFilters])

	useEffect(() => {
		loadCategoriesTemplate()
	}, [categoryParamss])

	useEffect(() => {
		if (categoryParamss !== "") return

		if (inputValue.length === "") return navigate("/templates")
	}, [inputValue])

	return (
		<>
			<Toaster
				toastOptions={{
					success: {
						style: {
							background: "#418d41",
							color: "white",
							padding: "12px",
						},
					},

					error: {
						style: {
							background: "#D32F2F",
							color: "white",
							padding: "12px",
						},
					},
				}}
				reverseOrder={false}
			/>
			<Loader loader={loader} />
			<ErrServer errLoad={errLoad} />
			<main className={`${loader ? "hidden" : "flex"} flex-col min-h-screen  `}>
				<section className="flex-grow">
					<button
						className={`${
							topScroll ? "block" : "hidden"
						} show fixed right-2 bottom-2 p-1 z-50 bg-dashboard-custom rounded-lg`}
						onClick={() => window.scrollTo(0, 0)}>
						<span className="material-symbols-outlined text-3xl">expand_less</span>
					</button>
					<div className="main-top fixed z-50 w-full bg-dark-custom top-0">
						<Navbar />
						<div className="flex justify-center items-center px-4">
							<div className="search py-4 max-sm:w-full flex items-center justify-center gap-2">
								<Menu setMenu={setMenu} menu={menu} menuRef={menuRef} setReserveTemplate={setReserveTemplate} />
								<div className="relative   max-sm:w-full">
									<input
										value={inputValue}
										onFocus={() => setShowOptions(true)}
										type="text"
										onClick={checkLengthCategories}
										placeholder="wyszukaj"
										className={`bg-input-main-color py-2 rounded-2xl pl-2 w-[27rem] text-xl max-sm:w-full ${
											templateCategory.length > 0 ? "pr-40" : "pr-24"
										} outline-none border-b focus:border-b border-input-main-color focus:border-orange-custom placeholder:text-lg`}
										onKeyDown={e => {
											if (searchTemplateParams === inputValue) return
											if (e.key === "Enter") return searchSystem()
										}}
										onChange={e => {
											setInputValue(e.target.value, setShowOptions(false))
										}}
									/>
									<div
										className={`${
											showOptions ? "block" : "hidden"
										} show absolute py-2 bg-input-main-color mt-2 w-full rounded-lg`}
										ref={filterRef}>
										<LoaderAnimation loaders={loaders} />
										<ul className={`${loaders ? "invisible" : "visible show"} w-full h-80 overflow-y-auto px-2`}>
											<li>Kategorie szablonów</li>
											{Object.keys(dataCategory).map((categories, index) => (
												<a href={`/templates?category=${categories}`}>
													<li
														className="py-1 flex hover:bg-[#38414e] rounded-lg justify-between items-center cursor-pointer  delay-75"
														key={index}
														onClick={() => {
															setCategoryFilters(categories)
															setTemplates([])
															setVisibleTemplate(0)
															setLoader(true)
														}}>
														<div className="flex justify-between w-full items-center gap-1 mr-1">
															<div className="flex items-center gap-1">
																<span className="material-symbols-outlined text-lg">double_arrow</span>
																<p className="text-lg">{categories}</p>
																<p className="font-black">{categories === categoryFilters ? "(wybrane)" : ""}</p>
															</div>
															<p className="text-orange-custom font-black">{dataCategory[categories]} Fraz</p>
														</div>
													</li>
												</a>
											))}
										</ul>
									</div>
									<div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-1">
										<p
											className={` text-sm ${
												totalCategoryTemplate > 0 ? "block" : "hidden"
											} bg-[#3c4553] px-2 rounded-md`}>
											{templateCategory.length}/{totalCategoryTemplate}
										</p>
										<button
											className={`bg-orange-custom rounded-lg px-2 h-fit w-20 ${
												searchTemplateParams === inputValue && templatesSearch.length !== 0
													? "cursor-not-allowed bg-[#178077]"
													: ""
											}`}
											onClick={searchSystem}
											disabled={searchTemplateParams === inputValue && templatesSearch.length !== 0}>
											{loadSearch ? "Czekaj.." : "Wyszukaj"}
										</button>
									</div>
								</div>
								<Tooltip id="my-tooltip" />
								<a
									className={`${totalCategoryTemplate !== null || templatesSearch.length > 0 ? "block" : "hidden"} `}
									href="/templates"
									data-tooltip-id="my-tooltip"
									data-tooltip-content={`${
										totalCategoryTemplate !== null
											? "Wyłącz sortowanie po kategorii"
											: "Wyłącz sortowanie po wyszukiwaniu"
									}`}>
									<span className="material-symbols-outlined font-black">close</span>
								</a>
							</div>
						</div>
					</div>

					{reserveTemplate === true && (
						<div className="fixed top-0 left-0 w-full h-full bg-black-transparent overflow-y-hidden z-50"></div>
					)}

					<ReserveTemplatePopup
						reserveTemplate={reserveTemplate}
						setReserveTemplate={setReserveTemplate}
						reserveTemplateValue={reserveTemplateValue}
						setReserveTemplateValue={setReserveTemplateValue}
						reserveTemplateValueErr={reserveTemplateValueErr}
						setReserveTemplateValueErr={setReserveTemplateValueErr}
						reserveTemplates={reserveTemplates}
					/>

					<div className="cards pb-5 m-0  flex justify-center flex-wrap items-start  px-2 gap-5 mt-36 max-w-container mx-auto">
						{typeVisible.map(template => (
							<Card template={template} categoryFilters={categoryFilters} />
						))}
					</div>
				</section>
				<div className="flex flex-col justify-center items-center">
					<div className="px-4">
						<p className={`${templatesSearch.length > 0 ? "block" : "hidden"} text-center text-lg`}>
							Znaleziono <span className="font-black text-orange-custom">{templatesSearch.length}</span>{" "}
							<span>
								{templatesSearch.length === 1
									? "szablon pasujący"
									: templatesSearch.length <= 4
									? "szablony pasujące"
									: "szablonów pasujących"}
							</span>{" "}
							do tej frazy
						</p>
						<p
							className={`text-center text-lg ${
								templateCategory.length === totalCategoryTemplate ? "block" : "hidden"
							}`}>
							Nie znaleziono więcej szablonów dla kategorii{" "}
							<span className="text-orange-custom font-black">{categoryFilters}</span>
						</p>
					</div>
					<Footer />
				</div>
			</main>
			<p className={`p-2 ${loadTemplate ? "block" : "hidden"}`}>Ładowanie...</p>
		</>
	)
}
